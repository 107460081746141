<template>
  <div>
    <v-container>
      <v-col cols="12" sm="12" md="12" lg="12">
        <v-card raised color="accent" flat>
          <!-- <v-toolbar color="primary" dark height="40%">
            <v-toolbar-title style="font-size:20px" class="formatoPalabras">
              <v-icon>
                mdi-information-outline
              </v-icon> 
              Contactanos
            </v-toolbar-title>
          </v-toolbar> -->
          <!-- Form contacto  -->
          <v-card-title style="color: #E41B41; font-size: x-large;" class="formatoPalabras"><strong>CONTACTO</strong></v-card-title>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row style="padding-left: 20px; padding-right: 20px; padding-top: 20px">
            <v-col cols="12" sm="12" md="12" style="padding-top: 0px">
              <label class="formatoPalabras"><strong>Nombre</strong></label>
              <v-text-field
                v-model="nombre"
                outlined
                clearable
                dense
                :rules="Rules"
                rounded
              ></v-text-field>
            </v-col>
            </v-row>
            <v-row style="padding-left: 20px; padding-right: 20px;">
            <v-col cols="12" sm="6" md="6" style="padding-top: 0px">
              <label class="formatoPalabras"><strong>Correo</strong></label>
              <v-text-field
                v-model="correo"
                outlined
                clearable
                dense
                :rules="Rules"
                rounded
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" style="padding-top: 0px">
              <label class="formatoPalabras"><strong>Telefono</strong></label>
              <v-text-field
                v-model="telefono"
                outlined
                clearable
                dense
                rounded
              ></v-text-field>
            </v-col>
            </v-row>
            <v-col cols="12" md="12">
              <label class="formatoPalabras"><strong>Mensaje</strong></label>
              <v-textarea
                v-model="mensaje"
                outlined
                name="input-7-4"
                :rules="Rules"
                rounded
              ></v-textarea>
            </v-col>
            <v-card-actions style="justify-content: center;">
              <v-btn
                color="primary"
                style="font-size:12px;float:center;"
                dark
                @click="enviarConsulta()"
                class="formatoPalabras"
              >
                <v-icon small>
                  mdi-email-send-outline
                </v-icon>&nbsp; Enviar</v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
    </v-container>
    <!-- Loading -->
    <LoadingCircular 
      :active.sync="loadingCarga" 
      :can-cancel="true"   
      color="#ed1841"         
      oader="dots"
      background-color="#252527"
      :is-full-page="true">
    </LoadingCircular>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import LoadingCircular from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import {getComprobanteCobro} from '../../util/pdfs'
import config from '../../../config'

export default {
  data() {
    return {
      nombre: "",
      correo: "",
      telefono: "",
      mensaje: "",
      valid: true,
      errors: false,
      Rules: [v => !!v || "Campo requerido."],
      dialogLoading: false,
      tituloModal: "",
      pdf: null,
      intentosLoginJarvis: 0
    }
  },
  created(){
    this.iniciarCarrito()
  },
  components: {
    LoadingCircular,
  },
  computed: {
    ...mapState(['articulosCarrito', 'loadingCarga', 'datosUsuario', 'respDatosApiJarvis', 'respEmail'])
  },
  methods: {
    ...mapActions(['loadCarrito', 'sendMailContacto', 'showLoading', 'closeLoading']),
    async iniciarCarrito(){
      await this.loadCarrito()
    },
    validate(){
      /* Funcion para validar los campos que son obligatorios */
      if (this.$refs.form.validate()) {
        /*Si algunas de las Rules no cumple,la funcion retornara 'false' */
        return (this.errors = true);
      }else{
        return (this.errors = false);
      }
    },
    validarEmail(){
      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (this.correo.length > 0){
        if (re.test(this.correo) == false){
          Swal.fire({
            icon: 'error',
            title: 'Email Inválido',
            text: 'Por favor, ingrese in Email Válido para proseguir.'
          })
          this.correo = ''
          return false
        }else{
          return true
        }
      }else{
        Swal.fire({
          icon: 'warning',
          title: 'Email Inválido',
          text: 'El Email no puede ser vacío.'
        })
        return false
      }
    },
    async enviarConsulta(){
      //validar campos
      if (!this.validate()) {
        return;
      }
      /*let datos = {
        transaccion: '613917682',
        descripcion: 'Compra de CeluShop',
        estado: 'Aprobado',
        fecha: '20/04/2022',
        hora: '10:20:20',
        titular: 'MURILLO MARCOS PAULO',
        documento: '33994156',
        telefono: '3876830801',
        tarjeta_marca: 'VISA',
        tarjeta_numero: '3874',
        financiacion: 'DEBITO',
        cod_autorizacion: '174459',
        lote: '0000',
        numero: '90',
        total: '10',
        logo: 'logo_celushop.png'
      }
      // ESTO ES PARA COBRO, COMENTADO POR EL MOMENTO
      this.pdf = await getComprobanteCobro(datos)
      this.pdf = 'data:application/pdf;base64,' + this.pdf*/
      // preparo el body de la ruta
      /*let body = {
        fromId: 1, // indico la casilla desde dónde va a salir el Email
        parametros: {
          nombre: 'Marcos Paulo Murillo',
          importe: 185000
        }, // parámetros para el template que voy a usar en el mail
        idTemplate: 1,
        nombreTemplate: 'celuShop',
        base64File: [
          {
            filename: 'cobro.pdf',
            content: this.pdf,
            encoding: 'base64'
          }
        ], // formato donde enviamos una cadena en base64 como archivo adjunto
        asunto: 'Cobro Exitoso',
        destinatario: 'marquitos070@gmail.com'
      }
      let opcionActual = {
        opcion: 7,
        bodyEmail: body
      }
      // llamada al envio de mail
      await this.sendMailContacto({bodyEmail: body, opcion: opcionActual})*/
      
      //download(this.pdf, "Cobro.pdf", "application/pdf")
      if (this.validarEmail()){
        Swal.fire({
          title: 'Contactar con Nuestro Personal',
          text: '¿Confirma el envío de mail?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Si',
          cancelButtonText: 'No'
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.showLoading()
            // ENVIO DE MAIL DE CONTACTO, preparo el body de la ruta
            let body = {
              fromId: 1, // indico la casilla desde dónde va a salir el Email
              parametros: {
                nombre: this.nombre,
                telefono: this.telefono,
                emailOrigen: this.correo,
                mensaje: this.mensaje
              }, // parámetros para el template que voy a usar en el mail
              idTemplate: 0,
              nombreTemplate: '',
              base64File: [], // formato donde enviamos una cadena en base64 como archivo adjunto
              asunto: 'Consultas sobre el Ecommerce CeluShop',
              destinatario: config.MAIL_DESTINATARIO_CONSULTAS
            }
            let opcionActual = {
              opcion: 8,
              bodyEmail: body
            }
            await this.sendMailContacto({bodyEmail: body, opcion: opcionActual})
          }
        })
      }
    }
  },
  watch: {
    datosUsuario: function(){
      if (Object.keys(this.datosUsuario).length > 0){
        if (this.datosUsuario.hasOwnProperty('resultado')){
          if (parseInt(this.datosUsuario.resultado) === 1){
            // es nuevo registro o login normal?
            if (parseInt(this.datosUsuario.tipo) === 1){
              // login normal
              Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Inicio de Sesión',
                text: 'Su Inicio de Sesión fue exitoso, espere a ser redireccionado',
                showConfirmButton: false,
                timer: 3000
              })
            }else if (parseInt(this.datosUsuario.tipo) === 2){
              // registro
              Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Nuevo Registro',
                text: 'Su registro fue exitoso, espere a ser redireccionado',
                showConfirmButton: false,
                timer: 3000
              })
            }else{
              // hizo logout
              setTimeout(() => [location.reload()], 2000)
            }
          }else{
            if (parseInt(this.datosUsuario.tipo) === 1){
              Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Inicio de Sesión',
                text: this.datosUsuario.msj,
                showConfirmButton: false,
                timer: 3000
              })
            }else if (parseInt(this.datosUsuario.tipo) === 2){
              Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Nuevo Registro',
                text: this.datosUsuario.msj,
                showConfirmButton: false,
                timer: 3000
              })
            }else{
              // hizo logout
              setTimeout(() => [location.reload()], 2000)
            }
          }
        }
      }
    },
    respDatosApiJarvis: async function(){
      if (Object.keys(this.respDatosApiJarvis).length > 0){
        if (parseInt(this.respDatosApiJarvis.resultado) === 1){
          if (parseInt(this.intentosLoginJarvis) <= 3){
            // iniciar nuevamente
            if (this.respDatosApiJarvis.opcionActual.opcion == 8){
              await this.sendMailContacto({bodyEmail: this.respDatosApiJarvis.opcionActual.bodyEmail, opcion: this.respDatosApiJarvis.opcionActual})
            }
          }else{
            console.log(this.respDatosApiJarvis.msj)
            this.dialogCarga = false
          }
        }
      }
    },
    respEmail: function(){
      if (Object.keys(this.respEmail).length > 0){
        this.closeLoading()
        if (this.respEmail.resultado == 1){
          // posible exito de envio de mail
          if (this.respEmail.emailResultado.resultado == 1){
            // exito
            Swal.fire({
              icon: 'success',
              title: 'Enviando mail de Contacto',
              text: '¡Envío exitoso!'
            })
            setTimeout(() => [location.reload()], 5000)
          }else{
            // error en envio de mail
            Swal.fire({
              icon: 'error',
              title: 'Enviando mail de Contacto',
              text: this.respEmail.emailResultado.message
            })
          }
        }else{
          // error en envio de mail
          Swal.fire({
            icon: 'error',
            title: 'Enviando mail de Contacto',
            text: this.respEmail.msj
          })
        }
      }
    }
  },
}
</script>

<style scoped>
  .formatoPalabras{
    font-family: 'Poppins', sans-serif;
  }
</style>