<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-card max-width="900" class="mx-auto" style="margin-top: 20px" color="accent" flat>
            <v-card-title style="color: #E41B41; font-size: x-large;" class="formatoPalabras"><strong>SUCURSALES</strong></v-card-title>
            <v-divider></v-divider>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <div v-for="(item, i) in sucursales" :key="i">
                    <v-col cols="12">
                      <v-card color="accent" flat>
                        <v-card-title style="font-size:22px" class="formatoPalabras">
                          <strong>{{ item.provincia }}</strong>
                        </v-card-title>
                        <v-list-item v-for="(item, i) in item.locales" :key="i" style="margin-top:-20px">
                          <v-list-item-icon>
                            <v-icon color="primary">mdi-map-marker-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content style="margin-left:-20px">
                            <v-list-item-title style="font-size:15px;white-space:pre-line;" v-text="item.direccion" class="formatoPalabras"></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-card>
                      <v-divider></v-divider>
                    </v-col>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <Loading :activo="dialogCarga"/>
    <!-- Loading -->
    <LoadingCircular 
      :active.sync="loadingCarga" 
      :can-cancel="true"   
      color="#ed1841"         
      oader="dots"
      background-color="#252527"
      :is-full-page="true">
    </LoadingCircular>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import Loading from '../../components/genericos/Loading.vue'
import LoadingCircular from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
export default {
  data(){
    return {
      sucursales: [],
      dialogCarga: false
    }
  },
  created(){
    this.iniciarCarrito()
    this.getSucursalesEcommerce()
  },
  components: {
    Loading,
    LoadingCircular
  },
  computed: {
    ...mapState(['respSucursales', 'articulosCarrito', 'loadingCarga', 'datosUsuario'])
  },
  methods: {
    ...mapActions(['getSucursales', 'loadCarrito']),
    async iniciarCarrito(){
      await this.loadCarrito()
    },
    async getSucursalesEcommerce(){
      this.dialogCarga = true
      await this.getSucursales()
    }
  },
  watch: {
    respSucursales: function(){
      if (Object.keys(this.respSucursales).length > 0){
        this.dialogCarga = false
        if (parseInt(this.respSucursales.resultado) === 1){
          // solo muestro los habilitados
          for (let id in this.respSucursales.sucursales){
            for (let jd in this.respSucursales.sucursales[id].locales){
              if (parseInt(this.respSucursales.sucursales[id].locales[jd].estado) === 0){
                delete this.respSucursales.sucursales[id].locales[jd]
              }
            }
          }
          this.sucursales = this.respSucursales.sucursales
        }
      }
    },
    datosUsuario: function(){
      if (Object.keys(this.datosUsuario).length > 0){
        if (this.datosUsuario.hasOwnProperty('resultado')){
          if (parseInt(this.datosUsuario.resultado) === 1){
            // es nuevo registro o login normal?
            if (parseInt(this.datosUsuario.tipo) === 1){
              // login normal
              Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Inicio de Sesión',
                text: 'Su Inicio de Sesión fue exitoso, espere a ser redireccionado',
                showConfirmButton: false,
                timer: 3000
              })
            }else if (parseInt(this.datosUsuario.tipo) === 2){
              // registro
              Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Nuevo Registro',
                text: 'Su registro fue exitoso, espere a ser redireccionado',
                showConfirmButton: false,
                timer: 3000
              })
            }else{
              // hizo logout
              setTimeout(() => [location.reload()], 2000)
            }
          }else{
            if (parseInt(this.datosUsuario.tipo) === 1){
              Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Inicio de Sesión',
                text: this.datosUsuario.msj,
                showConfirmButton: false,
                timer: 3000
              })
            }else if (parseInt(this.datosUsuario.tipo) === 2){
              Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Nuevo Registro',
                text: this.datosUsuario.msj,
                showConfirmButton: false,
                timer: 3000
              })
            }else{
              // hizo logout
              setTimeout(() => [location.reload()], 2000)
            }
          }
        }
      }
    }
  },
}
</script>

<style>
  .formatoPalabras{
    font-family: 'Poppins', sans-serif;
  }
</style>