<template>
  <v-container>
    <v-card raised flat color="accent">
      <!-- Detalle de la Compra -->
      <v-card-title 
        class="formatoPalabras"
        style="color: #E41B41; font-size: x-large;"
      >
        <strong>Mi Carrito</strong>
      </v-card-title>
      <!-- <v-toolbar 
        color="primary" 
        dark 
        height="50px"
        class="formatoPalabras"
      >
        <v-toolbar-title style="font-size: 19px;">
          <v-icon>mdi-cart-outline</v-icon>
          Mi Carrito
        </v-toolbar-title>
      </v-toolbar> -->
      <!-- Carrito para Movil -->
      <div v-if="movil">
        <v-card
          v-for="articulo in itemsCarrito"
          :key="articulo.articuloKey"
        >
          <v-card-title class="text-center formatoPalabras" style="word-break: normal;">
            {{ articulo.articuloNombre }}
            <v-layout align-end justify-end>
              <v-btn 
                icon
                color="primary"
                @click="eliminarArticuloCarrito(articulo)"
                title="Quitar del Carrito"
              >
                <v-icon>
                  mdi-delete-outline
                </v-icon>
              </v-btn>
            </v-layout>
          </v-card-title>
          <v-card-text>
            <v-row justify="center">
              <v-col cols="6">
                <v-card flat class="justificarImagen">
                  <v-img
                    :src="articulo.articuloImagenes[0].src"
                  ></v-img>
                </v-card>
              </v-col>
              <v-col cols="6">
                <v-row>
                  <v-col cols="12">
                    <h3 class="formatoPalabras">Total: </h3>
                    <h3 class="formatoPalabras">{{ articulo.precioNeto }}</h3>
                  </v-col>
                  <v-col cols="12">
                    <h3 style="padding-left: inherit;" class="formatoPalabras">Cantidad: </h3>
                    <v-btn title="Disminuir 1 unidad" icon @click="quitarCantidadArticulo(articulo)"><v-icon>mdi-minus-circle-outline</v-icon></v-btn>
                    <input type="text" v-model="articulo.cantidad" readonly class="text-center formatoPalabras" style="width: 30px;">
                    <v-btn title="Aumentar 1 unidad" icon @click="agregarCantidadArticulo(articulo)"><v-icon>mdi-plus-circle-outline</v-icon></v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row justify="center" v-if="articulo.errorStock">
              <v-alert dense text type="info">
                {{ articulo.msjErrorStock }}
              </v-alert>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card color="accent" flat>
          <v-card-title class="formatoPalabras pa-0">
            <v-layout align-end justify-end>
              <strong>SubTotal: ${{ calcularImporteTotal }}</strong>
            </v-layout>
          </v-card-title>
          <v-card-title class="formatoPalabras pa-0">
            <v-layout align-end justify-end>
              <strong>Total: ${{ calcularImporteTotal }}</strong>
            </v-layout>
          </v-card-title>
          <v-card-text>
            <v-row justify="center">
              <v-col cols="12" md="12" v-if="itemsCarrito.length == 0" class="text-center">
                <label class="formatoPalabras">Su carrito se encuentra vacio.Agregue articulos para continuar.</label>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="pa-0">
            <v-row class="pa-0 ma-0" justify="space-around">
              <v-btn
                color="secondary"
                style="font-size:12px"
                dark
                rounded
                @click="continuarComprando()"
                class="formatoPalabras"
              >
                Seguir Comprando
              </v-btn>
              <v-btn
                color="primary"
                style="font-size:12px"
                dark
                rounded
                @click="finalizarCompra()"
                class="formatoPalabras"
                v-if="itemsCarrito.length > 0"
              >
                Finalizar Compra
              </v-btn>
            </v-row>
          </v-card-actions>
        </v-card>
      </div>
      <!-- Carrito para Web -->
      <v-form  ref="form" v-model="valid" v-if="!movil" lazy-validation style="margin-top: 10px;">                    
        <v-simple-table fixed-header style="background-color: #f2f2f2">
          <template v-slot:default>
            <thead >
              <tr>
                <th class="text-left" id="encabezado" style="background-color: #f2f2f2"></th>
                <th class="text-left formatoPalabras black--text" id="encabezado" style="background-color: #f2f2f2">Articulo</th>
                <th class="text-center formatoPalabras black--text" id="encabezado" style="background-color: #f2f2f2">Cantidad</th>
                <th class="text-left formatoPalabras black--text" id="encabezado" style="background-color: #f2f2f2">Precio Unitario</th>
                <th class="text-left formatoPalabras black--text" id="encabezado" style="background-color: #f2f2f2">Total</th>
                <th class="text-left formatoPalabras" id="encabezado" style="background-color: #f2f2f2"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(articulo, index) in itemsCarrito" :key="index">
                <td>
                  <v-card 
                    max-height="100px" 
                    max-width="100px"
                    flat
                  >
                    <v-img 
                      :src="articulo.articuloImagenes[0].src"
                    ></v-img>
                  </v-card>
                </td>
                <td style="font-size:15px" class="formatoPalabras black--text">{{ articulo.articuloNombre }}</td>
                <td class="text-center formatoPalabras"  style="font-size:15px" >
                    <v-row justify="center">
                      <v-btn title="Disminuir 1 unidad" icon @click="quitarCantidadArticulo(articulo)"><v-icon>mdi-minus-circle-outline</v-icon></v-btn>
                      <input type="text" v-model="articulo.cantidad" readonly class="text-center" style="width: 30px;">
                      <v-btn title="Aumentar 1 unidad" icon @click="agregarCantidadArticulo(articulo)"><v-icon>mdi-plus-circle-outline</v-icon></v-btn>
                    </v-row>
                    <v-row justify="center" v-if="articulo.errorStock">
                      <v-alert dense text type="info">
                        {{ articulo.msjErrorStock }}
                      </v-alert>
                    </v-row>
                </td>
                <td style="font-size:15px;" class="formatoPalabras black--text">${{ Intl.NumberFormat("de-DE").format(articulo.articuloPrecio) }}</td>
                <td style="font-size:15px" class="formatoPalabras black--text"><strong>${{  Intl.NumberFormat("de-DE").format(articulo.precioNeto) }}</strong> </td>
                <td><v-icon color="primary" @click="eliminarArticuloCarrito(articulo)">mdi-delete-outline</v-icon> </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <br/>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="6" md="3" v-if="itemsCarrito.length > 0" class="pa-0">
            <label style="font-size:20px;margin-left:10px;color:#000000" class="formatoPalabras"><strong>SUBTOTAL</strong></label>
          </v-col>
          <v-col cols="6" md="3" v-if="itemsCarrito.length > 0" class="pa-0">
            <label  class="cart-summary">
              <strong class="order-total formatoPalabras" style="font-size:20px;color:#000000">${{ Intl.NumberFormat("de-DE").format(calcularImporteTotal) }}</strong>
            </label>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="12" v-if="itemsCarrito.length == 0" class="pa-0">
            <label style="margin-left:15px" class="formatoPalabras">Su carrito se encuentra vacio.Agregue articulos para continuar.</label>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="6" md="3" v-if="itemsCarrito.length > 0" class="pa-0">
            <label style="font-size:20px;margin-left:10px;color:#000000" class="formatoPalabras"><strong>TOTAL</strong></label>
          </v-col>
          <v-col cols="6" md="3" v-if="itemsCarrito.length > 0" class="pa-0">
            <label  class="cart-summary">
              <strong class="order-total formatoPalabras" style="font-size:20px;color:#000000">${{ Intl.NumberFormat("de-DE").format(calcularImporteTotal) }}</strong>
            </label>
          </v-col>
        </v-row>                       
        <v-card-actions class="pa-0">
          <v-row class="pa-0 ma-0" justify="space-around">
            <v-btn
              color="secondary"
              dark
              rounded
              large
              style="font-size:20px"
              @click="continuarComprando()"
              class="formatoPalabras ma-6"
            >
              Seguir Comprando
            </v-btn>
            <v-btn
              color="primary"
              dark
              rounded
              large
              style="font-size:20px"
              @click="finalizarCompra()"
              class="formatoPalabras ma-6"
              v-if="itemsCarrito.length > 0"
            >
              Finalizar Compra
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-form>
      <br/>
      <!-- Carrusel de Articulos Asociados -->
      <v-toolbar
        height="50px"
        color="accent"
        dark
        class="formatoPalabras"
        flat
      >
        <v-toolbar-title style="color: #E41B41; font-size: x-large;"><strong>ARTÍCULOS RELACIONADOS</strong></v-toolbar-title>
      </v-toolbar>
      <br/>
      <!-- <v-carousel
        cycle
        interval="20000"
        hide-delimiter-background 
        hide-delimiters
        height="470px"
      >
        <template v-for="(item, index) in itemsArtsAsociados"> 
          <v-carousel-item 
            v-if="(index + 1) % columns === 1 || columns === 1" 
            :key="index"
          > 
            <v-row class="flex-nowrap" style="height:100%"> 
              <template v-for="(n,i) in columns"> 
                <template v-if="(+index + i) < itemsArtsAsociados.length"> 
                  <v-col :key="i"> 
                    <v-sheet v-if="(+index + i) < itemsArtsAsociados.length"
                      height="100%"
                    >
                      <v-row 
                        class="fill-height"
                        align="center"
                        justify="center"
                      >
                        <UnArticuloCarrusel 
                          :datosArticulo="itemsArtsAsociados[+index + i]"
                          @asignarAlCarrito="agregarAlCarrito"
                        />
                      </v-row>
                    </v-sheet>
                  </v-col> 
                </template> 
              </template> 
            </v-row> 
          </v-carousel-item> 
        </template>
      </v-carousel> -->
      <div v-if="itemsArtsAsociados.length > 0">
        <VueSlickCarousel 
          :slidesToShow="columns" 
          :draggable="true" 
          :slidesToScroll="1" 
          :swipeToSlide="true"
          :arrows="true"
          :autoplay="true"
          :autoplaySpeed="5000"
        >
          <template #prevArrow>
            <v-btn color="primary" fab fixed class="mx-md-n4 mx-4"><v-icon>mdi-arrow-left</v-icon></v-btn>
          </template>
          <template #nextArrow>
            <v-btn color="primary" fab fixed class="mx-md-n4 mx-4"><v-icon>mdi-arrow-right</v-icon></v-btn>
          </template>
          <div
            v-for="(carr, index) in itemsArtsAsociados"
            :key="index"
          >
            <UnArticuloCarrusel 
              :datosArticulo="carr"
              @asignarAlCarrito="agregarAlCarrito"
            />
          </div>
        </VueSlickCarousel>
      </div>
    </v-card>
    <!-- Loading -->
    <Loading :activo="dialogCarga" />
    <!-- Loading -->
    <LoadingCircular 
      :active.sync="loadingCarga" 
      :can-cancel="true"   
      color="#ed1841"         
      oader="dots"
      background-color="#252527"
      :is-full-page="true">
    </LoadingCircular>
  </v-container>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import Loading from '../../components/genericos/Loading.vue'
import UnArticuloCarrusel from '../index/UnArticuloCarrusel.vue'
import router from '../../router'
import LoadingCircular from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import VueSlickCarousel from 'vue-slick-carousel'
export default {
  data() {
    return {
      dialogCarga: false,
      itemsCarrito: [],
      valid: true,
      itemsArtsAsociados: [],
      movil: false,
      pasarAComprar: false
    }
  },
  components: {
    Loading,
    UnArticuloCarrusel,
    LoadingCircular,
    VueSlickCarousel
  },
  created () {
    this.iniciarCarrito()
    this.isMovil()
  },
  computed: {
    ...mapState(['articulosCarrito', 'respArticulosAsociados', 'loadingCarga', 'datosUsuario', 'respArtsNoCarrito']),
    calcularImporteTotal(){
      let total = 0
      let index = 0
      for (index in this.itemsCarrito) {
        total +=
          parseFloat(this.itemsCarrito[index].precioNeto);
      }
      return total.toFixed(2);
    },
    columns() {
      if (this.$vuetify.breakpoint.xl) {
        return 6;
      }

      if (this.$vuetify.breakpoint.lg) {
        return 5;
      }

      if (this.$vuetify.breakpoint.md) {
        return 5;
      }

      if (this.$vuetify.breakpoint.sm) {
        return 4;
      }

      return 1;
    }
  },
  methods: {
    ...mapActions(['loadCarrito', 'removeArtCarrito', 'getCarritoAsociado', 'pushArtCarrito', 'getCarruselNoCarrito',
                  'showLoading', 'closeLoading']),
    async iniciarCarrito(){
      this.dialogCarga = true
      await this.loadCarrito()
    },
    quitarCantidadArticulo(articulo){
      let pos = this.itemsCarrito.indexOf(articulo)
      if (parseInt(pos) >= 0){
        // quito el msj de aviso de stock por si las chapas
        this.itemsCarrito[pos].errorStock = false
        if (parseInt(this.itemsCarrito[pos].cantidad) > 1){
          this.itemsCarrito[pos].cantidad = parseInt(this.itemsCarrito[pos].cantidad) - 1
        }else{
          this.itemsCarrito[pos].cantidad
        }
        let precioAnterior = this.itemsCarrito[pos].articuloPrecio
        this.itemsCarrito[pos].precioNeto = parseFloat(this.itemsCarrito[pos].cantidad * precioAnterior).toFixed(2)
        localStorage.setItem('carritoUsuario', JSON.stringify(this.itemsCarrito))
      }
    },
    agregarCantidadArticulo(articulo){
      let pos = this.itemsCarrito.indexOf(articulo)
      if (parseInt(pos) >= 0){
        // debo controlar que tengo stock suficiente para la cantidad que demanda el cliente
        if (parseInt(articulo.stock.stockTotal) >= parseInt(this.itemsCarrito[pos].cantidad) + 1){
          // tengo para venderle
          this.itemsCarrito[pos].cantidad = parseInt(this.itemsCarrito[pos].cantidad) + 1
          let precioAnterior = this.itemsCarrito[pos].articuloPrecio
          this.itemsCarrito[pos].precioNeto = parseFloat(this.itemsCarrito[pos].cantidad * precioAnterior).toFixed(2)
          localStorage.setItem('carritoUsuario', JSON.stringify(this.itemsCarrito))
        }else{
          // ya no tengo para venderle, no debo aumentar, debo mostrar msj
          this.itemsCarrito[pos].msjErrorStock = 'El máximo disponible son ' + this.itemsCarrito[pos].cantidad + ' unidades'
          this.itemsCarrito[pos].errorStock = true
        }
      }
    },
    async eliminarArticuloCarrito(articulo){
      await this.removeArtCarrito(articulo)
    },
    async agregarAlCarrito(articuloSeleccionado){
      // articuloSeleccionado debe ser un objeto
      if (Object.keys(articuloSeleccionado).length > 0){
        // ya se encuentra en el carrito? las cantidades son editables, por eso no busco exactamente el objeto
        if (this.articulosCarrito.length > 0){
          let cont = 0
          for (let id in this.articulosCarrito){
            if (this.articulosCarrito[id].articuloKey == articuloSeleccionado.articuloKey){
              cont = cont + 1
            }
          }
          if (parseInt(cont) === 0){
            await this.pushArtCarrito(articuloSeleccionado)
            this.$store.dispatch('executeSnackBar', {msj: 'Artículo Agregado al Carrito', color: 'success', activo: true})
          }else{
            // ya se encuentra en el carrito
            this.$store.dispatch('executeSnackBar', {msj: 'El Artículo seleccionado ya se encuentra en el Carrito', color: 'warning', activo: true})
          }
        }else{
          await this.pushArtCarrito(articuloSeleccionado)
          this.$store.dispatch('executeSnackBar', {msj: 'Artículo Agregado al Carrito', color: 'success', activo: true})
        }
      }
    },
    continuarComprando(){
      router.push({path: '/'})
    },
    toTop(){
      this.$vuetify.goTo(0)
    },
    async mostrarNuevosArticulos(){
      await this.getCarruselNoCarrito()
    },
    isMovil(){
			var mobile = {
        Android: function() {
          return navigator.userAgent.match(/Android/i);
        },
        BlackBerry: function() {
          return navigator.userAgent.match(/BlackBerry/i);
        },
        iOS: function() {
          return navigator.userAgent.match(/iPhone|iPad|iPod/i);
        },
        Opera: function() {
          return navigator.userAgent.match(/Opera Mini/i);
        },
        Windows: function() {
          return navigator.userAgent.match(/IEMobile/i);
        },
        any: function() {
          return (mobile.Android() || mobile.BlackBerry() || mobile.iOS() || mobile.Opera() || mobile.Windows());
        }
      }
      // controlo la salida de mobile
      if (Array.isArray(mobile.any())){
        if (mobile.any().length > 0){
          this.movil = true
        }
      }
    },
    async finalizarCompra(){
      // controlo nuevamente a ver si tengo stock para lo que me pide el cliente
      this.showLoading()
      this.pasarAComprar = true
      await this.loadCarrito()
    }
  },
  watch: {
    articulosCarrito: async function(){
      if (this.articulosCarrito.length > 0){
        this.itemsCarrito = this.articulosCarrito
        // capturo marca y modelo de cada uno de ellos
        let filtros = []
        for (let id in this.itemsCarrito){
          let marcaModelo = {
            marca: parseInt(this.itemsCarrito[id].articuloMarcaCodigo),
            modelo: parseInt(this.itemsCarrito[id].articuloModeloCodigo),
            categoria: parseInt(this.itemsCarrito[id].articuloCatCodigo)
          }
          filtros.push(marcaModelo)
        }
        await this.getCarritoAsociado(filtros)
      }else{
        // no tiene carrito, por el momento le muestro articulos nuevos
        this.mostrarNuevosArticulos()
      }
    },
    respArticulosAsociados: async function(){
      this.dialogCarga = false
      if (Object.keys(this.respArticulosAsociados).length > 0){
        if (parseInt(this.respArticulosAsociados.resultado) === 1){
          this.itemsArtsAsociados = []
          let artsPeticion = await this.$store.dispatch('articulos/getArrayArticulos', {articulos: this.respArticulosAsociados.articulos, opcion: 0, categoria: 0, imgGrandes: 0})
          if (artsPeticion.resultado == 0){
            this.dialogCarga = false
            this.$store.dispatch('executeSnackBar', {msj: artsPeticion.msj, color: 'error', activo: true})
            return
          }
          this.itemsArtsAsociados = artsPeticion.listado
        }
      }
      this.toTop()
    },
    datosUsuario: function(){
      if (Object.keys(this.datosUsuario).length > 0){
        if (this.datosUsuario.hasOwnProperty('resultado')){
          if (parseInt(this.datosUsuario.resultado) === 1){
            // es nuevo registro o login normal?
            if (parseInt(this.datosUsuario.tipo) === 1){
              // login normal
              Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Inicio de Sesión',
                text: 'Su Inicio de Sesión fue exitoso, espere a ser redireccionado',
                showConfirmButton: false,
                timer: 3000
              })
            }else if (parseInt(this.datosUsuario.tipo) === 2){
              // registro
              Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Nuevo Registro',
                text: 'Su registro fue exitoso, espere a ser redireccionado',
                showConfirmButton: false,
                timer: 3000
              })
            }else{
              // hizo logout
              setTimeout(() => [location.reload()], 2000)
            }
          }else{
            if (parseInt(this.datosUsuario.tipo) === 1){
              Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Inicio de Sesión',
                text: this.datosUsuario.msj,
                showConfirmButton: false,
                timer: 3000
              })
            }else if (parseInt(this.datosUsuario.tipo) === 2){
              Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Nuevo Registro',
                text: this.datosUsuario.msj,
                showConfirmButton: false,
                timer: 3000
              })
            }else{
              // hizo logout
              setTimeout(() => [location.reload()], 2000)
            }
          }
        }
      }
    },
    respArtsNoCarrito: async function(){
      this.dialogCarga = false
      if (Object.keys(this.respArtsNoCarrito).length > 0){
        if (parseInt(this.respArtsNoCarrito.resultado) === 1){
          let artsPeticion = await this.$store.dispatch('articulos/getArrayArticulos', {articulos: this.respArtsNoCarrito.articulos, opcion: 0, categoria: 0, imgGrandes: 0})
          if (artsPeticion.resultado == 0){
            this.dialogCarga = false
            this.$store.dispatch('executeSnackBar', {msj: artsPeticion.msj, color: 'error', activo: true})
            return
          }
          this.itemsArtsAsociados = artsPeticion.listado
        }
      }
      this.toTop()
    },
    itemsCarrito: function(){
      if (this.itemsCarrito.length > 0){
        let cont = 0
        // el cliente puede llevar la cantidad que esta pidiendo? control de cantidad solicitada vs stock disponible
        for (let id in this.itemsCarrito){
          if (parseInt(this.itemsCarrito[id].stock.stockTotal) < parseInt(this.itemsCarrito[id].cantidad)){
            // no puedo cubrir la demanda, pero es porque tengo stock en 0?
            if (parseInt(this.itemsCarrito[id].stock.stockTotal) === 0){
              this.itemsCarrito[id].cantidad = this.itemsCarrito[id].stock.stockTotal
              this.itemsCarrito[id].msjErrorStock = 'Sin Stock Disponible'
            }else{
              this.itemsCarrito[id].cantidad = this.itemsCarrito[id].stock.stockTotal
              this.itemsCarrito[id].msjErrorStock = 'El máximo disponible son ' + this.itemsCarrito[id].cantidad + ' unidades'
            }
            this.itemsCarrito[id].errorStock = true
            cont = cont + 1
          }else{
            // limpio los posibles errores que hayan arrastrado
            this.itemsCarrito[id].msjErrorStock = ''
            this.itemsCarrito[id].errorStock = false
          }
        }
        this.closeLoading()
        if (parseInt(cont) === 0 && this.pasarAComprar == true){
          // redireccionar
          //this.$router.push('/checkout')
          router.push({name: 'CheckOut'})
        }else{
          this.pasarAComprar = false
        }
      }
    }
  },
}
</script>

<style>
  .formatoPalabras{
    font-family: 'Poppins', sans-serif;
  }
  .justificarImagen{
    width: 100px;
  }
</style>