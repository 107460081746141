<template>
  <div>
    <v-container fluid class="formatoPalabras">
      <v-card
        flat
        :min-height="windowHeight"
        style="background-color: #f2f2f2"
      >
        <v-card-title style="background-color: #f2f2f2;">
          <v-spacer></v-spacer>
          <p class="text-center" style="word-break: normal;">Tu Número de Pedido {{ $route.params.id }} tiene una Solicitud de Prestamo en estado <strong class="ml-1" :style="(estadoPrestamo == 'ABORTADA' || estadoPrestamo == 'RECHAZADA' || estadoPrestamo == 'EXPIRADA') ? 'color: #E41B41' : estadoPrestamo == 'CREADA' ? 'color: #2196F3' : 'color: #4CAF50'">{{ estadoPrestamo }}</strong></p>
          <v-spacer></v-spacer>
        </v-card-title>
        <!-- Msj en caso que este creada -->
        <v-card-text style="background-color: #f2f2f2" v-if="estadoPrestamo == 'CREADA' || estadoPrestamo == 'PENDIENTE'" class="pb-0">
          <v-row>
            <v-col cols="12">
              <p class="text-center" style="word-break: normal;">Puedes completar la solicitud ingresando al siguiente enlace: <a :href="link" target="_blank">{{ link }}</a></p>
            </v-col>
          </v-row>
        </v-card-text>
        <!-- Msj en caso que se haya rechazado por cualquier motivo -->
        <v-card-text style="background-color: #f2f2f2" v-if="estadoPrestamo == 'ABORTADA' || estadoPrestamo == 'RECHAZADA' || estadoPrestamo == 'EXPIRADA'" class="pb-0">
          <v-row>
            <v-col cols="12">
              <p class="text-center" style="word-break: normal;">La Solicitud de Prestamo no fue aprobada. Puedes intentar nuevamente generando otra compra.</p>
            </v-col>
          </v-row>
        </v-card-text>
        <!-- Msj en caso que esté Aceptado -->
        <v-card-text style="background-color: #f2f2f2" v-if="estadoPrestamo == 'ACEPTADA' || estadoPrestamo == 'FINALIZADA'">
          <v-row>
            <v-col cols="12">
              <p class="text-center" style="word-break: normal;">Pronto nos estaremos comunicando a tu teléfono o a tu correo electrónico para acordar la entrega de tus productos. Muchas gracias por tu compra.</p>
            </v-col>
          </v-row>
        </v-card-text>
        <!-- Msj en caso que esté Aprobada por el Ecommerce o bien Finalizada -->
        <v-card-text style="background-color: #f2f2f2" v-if="estadoPrestamo == 'APROBADA POR EL ECOMMERCE'">
          <v-row>
            <v-col cols="12">
              <p class="text-center" style="word-break: normal;">Tu pedido ya fue entregado. Muchas gracias por tu Compra.</p>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-title class="pt-0">
          <v-spacer></v-spacer>
          Detalle de la Compra
          <v-spacer></v-spacer>
        </v-card-title>
        <!-- Detalle de Compra para Web -->
        <v-card-text v-if="movil == false">
          <v-row>
            <v-col cols="12">
              <v-simple-table fixed-header style="background-color: #f2f2f2">
                <template v-slot:default>
                  <thead >
                    <tr>
                      <th class="text-left" style="background-color: #f2f2f2"></th>
                      <th class="text-left black--text" style="background-color: #f2f2f2">Articulo</th>
                      <th class="text-center black--text" style="background-color: #f2f2f2">Cantidad</th>
                      <th class="text-left black--text" style="background-color: #f2f2f2">Precio Unitario</th>
                      <th class="text-left black--text" style="background-color: #f2f2f2">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(articulo, index) in itemsCarrito" :key="index">
                      <td>
                        <v-card 
                          max-height="100px" 
                          max-width="100px"
                          flat
                        >
                          <v-img 
                            :src="articulo.imgSrc"
                          ></v-img>
                        </v-card>
                      </td>
                      <td style="font-size:15px" class="black--text">{{ articulo.nombre }}</td>
                      <td class="text-center"  style="font-size:15px" >
                        <v-row justify="center">
                          <input type="text" v-model="articulo.cantidad" readonly class="text-center" style="width: 30px;">
                        </v-row>
                      </td>
                      <td style="font-size:15px;" class="black--text">${{ Intl.NumberFormat("de-DE").format(articulo.precio) }}</td>
                      <td style="font-size:15px" class="black--text"><strong>${{  Intl.NumberFormat("de-DE").format(articulo.importe) }}</strong> </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td><h2 style="color: black;">Subtotal: </h2></td>
                      <td><h2 style="color: black;">${{ Intl.NumberFormat("de-DE").format(subtotal) }}</h2></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td><h2 style="color: black;">Costo del envío: </h2></td>
                      <td><h2 style="color: black;">${{ Intl.NumberFormat("de-DE").format(precioEnvio) }}</h2></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td><h2 style="color: black;"><strong>Total: </strong></h2></td>
                      <td><h2 style="color: black;"><strong>${{ Intl.NumberFormat("de-DE").format(total) }}</strong></h2></td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
        <!-- Detalle de Compra para Movil -->
        <v-card-text v-if="movil == true">
          <v-row>
            <v-col cols="12">
              <v-card
                flat
                v-for="(articulo, index) in itemsCarrito"
                :key="index"
              >
                <v-card-title class="pb-0">
                  <v-spacer></v-spacer>
                  <p class="text-center" style="word-break: normal;">{{ articulo.nombre }}</p>
                  <v-spacer></v-spacer>
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <v-img :src="articulo.imgSrc"></v-img>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" class="pa-0">
                      <p class="text-center" style="font-size: 20px;">Cantidad: {{ articulo.cantidad }}</p>
                    </v-col>
                    <v-col cols="12" class="pa-0">
                      <p class="text-center" style="font-size: 20px;">Precio: {{ Intl.NumberFormat("de-DE").format(articulo.precio) }}</p>
                    </v-col>
                    <v-col cols="12" class="pa-0">
                      <p class="text-center" style="font-size: 20px;">Importe: {{ Intl.NumberFormat("de-DE").format(articulo.importe) }}</p>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <!-- Totales -->
          <v-row justify="end">
            <v-col cols="12">
              <!-- SubTotal -->
              <v-row>
                <v-col cols="6" style="padding-top: 5px;padding-bottom: 0px">
                  <h4 style="color: black;">Subtotal: </h4>
                </v-col>
                <v-col cols="6" style="padding-top: 5px;padding-bottom: 0px">
                  <h4 style="color: black;" class="text-end">${{ Intl.NumberFormat("de-DE").format(subtotal) }}</h4>
                </v-col>
              </v-row>
              <!-- Costo de Envio -->
              <v-row>
                <v-col cols="6" style="padding-top: 5px;padding-bottom: 0px">
                  <h4 style="color: black;">Costo del envío: </h4>
                </v-col>
                <v-col cols="6" style="padding-top: 5px;padding-bottom: 0px">
                  <h4 style="color: black;" class="text-end">${{ Intl.NumberFormat("de-DE").format(precioEnvio) }}</h4>
                </v-col>
              </v-row>
              <!-- Total -->
              <v-row>
                <v-col cols="6" style="padding-top: 5px;padding-bottom: 0px">
                  <h2 style="color: black;"><strong>Total: </strong></h2>
                </v-col>
                <v-col cols="6" style="padding-top: 5px;padding-bottom: 0px">
                  <h2 style="color: black;" class="text-end"><strong>${{ Intl.NumberFormat("de-DE").format(total) }}</strong></h2>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pa-0">
          <v-row class="pa-0 ma-0" justify="space-around">
            <v-btn
              color="secondary"
              dark
              rounded
              large
              style="font-size:20px"
              class="ma-6"
              @click="volverInicio()"
            >
              Seguir Comprando
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-container>
    <!-- Loading -->
    <Loading :activo="dialogCarga" />
    <!-- Modal Aviso -->
    <ModalAviso 
      :datos="dialogAviso"
      @setearModalAviso="setModalAviso"
      @action="volverInicio()"
    />
  </div>
</template>

<script>
import router from '../../router'
import 'vue-loading-overlay/dist/vue-loading.css'
import Loading from '../../components/genericos/Loading.vue'
import { mapActions, mapState } from 'vuex'
import ModalAviso from '../genericos/modalAviso.vue'
import { roundNumber } from '../../util/util'
import moment from 'moment'
export default {
  components: {
    Loading,
    ModalAviso
  },
  created () {
    this.isMovil()
    this.initMisPrestamos()
    this.iniciarCarrito()
  },
  data() {
    return {
      estadoPrestamo: '',
      dialogCarga: false,
      dialogAviso: {
        activo: false,
        titulo: '',
        mensaje: ''
      },
      link: '',
      windowHeight: window.innerHeight - 250,
      itemsCarrito: [],
      subtotal: 0,
      total: 0,
      movil: false,
      estadoApi: '',
      precioEnvio: 0,
      rechazoMotivo: '',
      descripRechazo: ''
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize());
    })
  },
  beforeDestroy() { 
    window.removeEventListener('resize', this.onResize()); 
  },
  computed: {
    ...mapState(['articulosCarrito'])
  },
  methods: {
    ...mapActions(['showLoading', 'closeLoading', 'loadCarrito']),
    onResize() {
      this.windowHeight = window.innerHeight - 250
    },
    async iniciarCarrito(){
      await this.loadCarrito()
    },
    async initMisPrestamos(){
      if (Object.keys(this.$route.params).length == 0){
        this.volverInicio()
        return
      }
      this.dialogCarga = true
      // obtengo los estados de RapiCompras
      let estadosPeticion = await this.$store.dispatch('rapiCompra/getEstadosRapiCompra')
      if (estadosPeticion.resultado == 0){
        this.dialogCarga = false
        this.dialogAviso.titulo = 'Consultar Solicitud de Prestamo'
        this.dialogAviso.mensaje = 'Ocurrió un problema general al obtener los estados de RapiCompras. Por favor, intente nuevamente más tarde.'
        this.dialogAviso.activo = true
        return
      }
      // obtengo los datos del prestamo en oracle
      let prestamoOraclePeticion = await this.$store.dispatch('rapiCompra/getPrestamoOracle', {
        nro_pedido: this.$route.params.id,
        tipo_pedido: 20,
        dni: ''
      })
      if (prestamoOraclePeticion.resultado == 0){
        this.dialogCarga = false
        this.dialogAviso.titulo = 'Consultar Solicitud de Prestamo'
        this.dialogAviso.mensaje = 'Ocurrió un problema general al consultar la Solicitud de Prestamo. Por favor, intente nuevamente más tarde.'
        this.dialogAviso.activo = true
        return
      }
      if (prestamoOraclePeticion.prestamos.length == 0){
        this.dialogCarga = false
        this.dialogAviso.titulo = 'Consultar Solicitud de Prestamo'
        this.dialogAviso.mensaje = 'No se encontraron datos del Pedido ingresado.'
        this.dialogAviso.activo = true
        return
      }
      if (prestamoOraclePeticion.prestamos[0].id_prestamo == null || prestamoOraclePeticion.prestamos[0].id_prestamo == undefined){
        this.dialogCarga = false
        this.dialogAviso.titulo = 'Consultar Solicitud de Prestamo'
        this.dialogAviso.mensaje = 'No se encontraron datos del Pedido solicitado.'
        this.dialogAviso.activo = true
        return
      }
      // obtengo el prestamo desde la API DE MACRO
      let prestamoMacroPeticion = await this.$store.dispatch('rapiCompra/getPrestamoMACRO', {id_prestamo: prestamoOraclePeticion.prestamos[0].id_prestamo})
      if (prestamoMacroPeticion.resultado == 0 || (prestamoMacroPeticion.prestamo.hasOwnProperty('code') && (prestamoMacroPeticion.prestamo.code == 404 || prestamoMacroPeticion.prestamo.code == 409))){
        this.dialogCarga = false
        this.dialogAviso.titulo = 'Consultar Solicitud de Prestamo'
        this.dialogAviso.mensaje = 'No se pudo consultar la Solicitud de Prestamo en MACRO. Por favor, intente nuevamente más tarde.'
        this.dialogAviso.activo = true
        return
      }
      // obtengo el motivo y descripcion de rechazo
      if (prestamoMacroPeticion.prestamo.hasOwnProperty('rejectedCode') && prestamoMacroPeticion.prestamo.rejectedCode != null && 
      prestamoMacroPeticion.prestamo.rejectedCode != undefined && prestamoMacroPeticion.prestamo.rejectedCode.toString().length > 0) this.rechazoMotivo = prestamoMacroPeticion.prestamo.rejectedCode
      if (prestamoMacroPeticion.prestamo.hasOwnProperty('rejectedDescription') && prestamoMacroPeticion.prestamo.rejectedDescription != null && 
      prestamoMacroPeticion.prestamo.rejectedDescription != undefined && prestamoMacroPeticion.prestamo.rejectedDescription.toString().length > 0) this.descripRechazo = prestamoMacroPeticion.prestamo.rejectedDescription
      // obtengo el estado actual
      let unEstado = estadosPeticion.estados.filter(element => element.nombre_api.trim().toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") == prestamoMacroPeticion.prestamo.status.trim().toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
      if (unEstado.length == 0){
        this.estadoPrestamo = 'PENDIENTE'
        this.estadoApi = 'CREATED'
      }else{
        this.estadoPrestamo = unEstado[0].nombre.toUpperCase()
        this.estadoApi = unEstado[0].nombre_api.toUpperCase()
      }
      // asigno el link
      this.link = prestamoMacroPeticion.prestamo.urlLenderInitFlow
      // obtengo el carrito de firebase
      let firebasePeticion = await this.$store.dispatch('firebaseService/getUnPedidoFirebase', {
        nro_pedido: this.$route.params.id
      })
      if (firebasePeticion.resultado == 0){
        this.dialogCarga = false
        this.dialogAviso.titulo = 'Consultar Solicitud de Prestamo'
        this.dialogAviso.mensaje = 'No se pudo obtener el carrito de compra de su Solicitud de Prestamo. Por favor, intente nuevamente más tarde.'
        this.dialogAviso.activo = true
        return
      }
      // precio de envio?
      if (firebasePeticion.pedido.hasOwnProperty('datos_entrega') && firebasePeticion.pedido.datos_entrega != null && firebasePeticion.pedido.datos_entrega != undefined &&
      typeof firebasePeticion.pedido.datos_entrega == 'object' && firebasePeticion.pedido.datos_entrega.hasOwnProperty('costo_envio') &&
      firebasePeticion.pedido.datos_entrega.costo_envio != null && firebasePeticion.pedido.datos_entrega.costo_envio != undefined &&
      firebasePeticion.pedido.datos_entrega.costo_envio.toString().length > 0) this.precioEnvio = roundNumber(firebasePeticion.pedido.datos_entrega.costo_envio, 2)
      // obtuve mi pedido, paso a armar los articulos
      let arts = firebasePeticion.pedido.articulos
      if (arts != null && arts != undefined && typeof arts == 'object' && !Array.isArray(arts)){
        let unArt = {
          imgSrc: arts.img_principal,
          nombre: arts.nombre,
          cantidad: parseInt(arts.cantidad),
          precio: roundNumber(arts.precio, 2),
          importe: roundNumber(roundNumber(arts.precio, 2) * parseInt(arts.cantidad), 2)
        }
        this.itemsCarrito.push(unArt)
      }
      if (arts != null && arts != undefined && Array.isArray(arts)){
        for (let id in arts){
          let unArt = {
            imgSrc: arts[id].img_principal,
            nombre: arts[id].nombre,
            cantidad: parseInt(arts[id].cantidad),
            precio: roundNumber(arts[id].precio, 2),
            importe: roundNumber(roundNumber(arts[id].precio, 2) * parseInt(arts[id].cantidad), 2)
          }
          this.itemsCarrito.push(unArt)
        }
      }
      if (this.estadoPrestamo != 'CREADA' || this.estadoPrestamo == 'PENDIENTE DE CREAR'){
        if (localStorage.getItem('pedidoWeb')){
          let pedidoWeb = JSON.parse(localStorage.getItem('pedidoWeb'))
          if (this.$route.params.id == pedidoWeb.pedidoWeb) localStorage.removeItem('pedidoWeb')
        }
        this.actualizarPrestamo(firebasePeticion.pedido, prestamoOraclePeticion.prestamos[0])
        return
      }
      this.dialogCarga = false
    },
    async actualizarPrestamo(pedidoFire, pedidoOracle){
      // paso a actualizar el estado de los pedidos en firebase y en oracle, primero actualizo en firebase
      // 06-07-2023 por MMURILLO, como no vamos a poder controlar el cambio de estado y desde RAPICOMPRAS seguramente van a cambiar el estado en nuestro sistema más de una vez
      // dejo de controlar que el estado del pedido de oracle y el de firebase sea 'CREADO' Y 'PENDIENTE' respectivamente
      // POR LO TANTO SIEMPRE ACTUALIZO EL ESTADO CADA VEZ QUE ACTUALICEN LA PAGINA
      //if (pedidoFire.estado == 'PENDIENTE'){
        // paso a actualizar en Firebase
        let pedido = pedidoFire
        // 29-08-2023 por MMURILLO, vamos a actualizar el estado en Firebase siempre y cuando no se encuentre entregada
        if (pedido.estado != 'ENTREGADO'){
          // asigno el nuevo estado
          pedido.estado = (this.estadoPrestamo == 'ACEPTADA' || this.estadoPrestamo == 'FINALIZADA') ? 'APROBADO' : this.estadoPrestamo
          if (this.estadoPrestamo == 'RECHAZADA' || this.estadoPrestamo == 'ABORTADA' || this.estadoPrestamo == 'EXPIRADA') pedido.estado = 'RECHAZADO'
          pedido.numero_pedido = this.$route.params.id
          pedido.fecha_cobro = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
          if (this.estadoPrestamo == 'ACEPTADA' || this.estadoPrestamo == 'FINALIZADA'){
            pedido.estado_pedido.codigo = 1
            pedido.estado_pedido.estado = 'Pendiente de Envio'
          }
          // esta aprobada por el ecommerce?
          // if (this.estadoPrestamo == 'APROBADA POR EL ECOMMERCE' || this.estadoPrestamo == 'FINALIZADA'){
          //   pedido.estado_pedido.codigo = 5
          //   pedido.estado_pedido.estado = 'Entregado al Cliente'
          // }
          let updateFirePeticion = await this.$store.dispatch('firebaseService/updateRapiCompraFirebase', {
            nro_pedido: this.$route.params.id,
            pedido: pedido
          })
          if (updateFirePeticion.resultado == 0){
            this.dialogCarga = false
            this.dialogAviso.titulo = 'Consultar Solicitud de Prestamo'
            this.dialogAviso.mensaje = 'No se pudo actualizar los valores de la Solicitud de Prestamo. Por favor, intente nuevamente más tarde.'
            this.dialogAviso.activo = true
            return
          }
        }
      //}
      // paso a actualizar en ORACLE
      //if (pedidoOracle.estado_id != 8 && pedidoOracle.estado_id != 10){
        // solo actualizo el estado
        let updateOraPeticion = await this.$store.dispatch('rapiCompra/setEstadoSolPrestamo', {
          estado: this.estadoApi,
          url: '',
          nro_pedido: this.$route.params.id,
          rechazoMotivo: this.rechazoMotivo,
          descripRechazo: this.descripRechazo
        })
        if (updateOraPeticion.resultado == 0){
          this.dialogCarga = false
          this.dialogAviso.titulo = 'Consultar Solicitud de Prestamo'
          this.dialogAviso.mensaje = 'No se pudo actualizar actualizar el estado de la Solicitud de Prestamo. Por favor, intente nuevamente más tarde.'
          this.dialogAviso.activo = true
          return
        }
      //}
      this.dialogCarga = false
    },
    volverInicio(){
      router.push({name: 'Home'})
    },
    setModalAviso(value){
      this.dialogAviso.activo = value
      if (this.dialogAviso.activo == false){
        this.dialogAviso.titulo = ''
        this.dialogAviso.mensaje = ''
      }
    },
    isMovil(){
      this.movil = false
			var mobile = {
        Android: function() {
          return navigator.userAgent.match(/Android/i);
        },
        BlackBerry: function() {
          return navigator.userAgent.match(/BlackBerry/i);
        },
        iOS: function() {
          return navigator.userAgent.match(/iPhone|iPad|iPod/i);
        },
        Opera: function() {
          return navigator.userAgent.match(/Opera Mini/i);
        },
        Windows: function() {
          return navigator.userAgent.match(/IEMobile/i);
        },
        any: function() {
          return (mobile.Android() || mobile.BlackBerry() || mobile.iOS() || mobile.Opera() || mobile.Windows());
        }
      }
      // controlo la salida de mobile
      if (Array.isArray(mobile.any())){
        if (mobile.any().length > 0){
          this.movil = true
        }
      }
    }
  },
  watch: {
    itemsCarrito: function(){
      this.subtotal = 0
      this.total = 0
      if (this.itemsCarrito.length > 0){
        for (let id in this.itemsCarrito){
          this.subtotal = this.subtotal + roundNumber(this.itemsCarrito[id].importe, 2)
        }
      }
      // calculo el total
      this.total = roundNumber(this.subtotal + this.precioEnvio, 2)
    }
  },
}
</script>

<style scoped>
  .formatoPalabras{
    font-family: 'Poppins', sans-serif;
  }
</style>