<template>
  <div>
    <!-- Loading -->
    <LoadingCircular 
      :active.sync="loadingCarga" 
      :can-cancel="true"   
      color="#ed1841"         
      oader="dots"
      background-color="#252527"
      :is-full-page="true">
    </LoadingCircular>
    <!-- Carrusel de Banners -->
    <v-container class="pa-0" :fluid="!$vuetify.breakpoint.xl ? true : false">
      <v-carousel 
        :show-arrows="false" 
        cycle
        v-bind:height="$vuetify.breakpoint.width > 1904 ? '500px' : $vuetify.breakpoint.width > 960 ? '350px' : 'auto'"
      >
        <v-carousel-item
          v-for="(item,i) in items"
          :key="i"
          :src="item.src"
          :href="item.redirect"
          target="_blank"
        ></v-carousel-item>
      </v-carousel>
    </v-container>
    <v-container>
      <br v-if="visibleCarrusel1 || visibleCarrusel2" />
      <!-- Carrusel 1 -->
      <v-toolbar
        height="50px"
        dark
        v-if="visibleCarrusel1"
        color="accent"
        flat
      >
        <v-toolbar-title class="otroFormato" style="color: #E41B41; font-size: x-large;"><strong>{{ tituloCarrusel1.toUpperCase() }}</strong></v-toolbar-title>
      </v-toolbar>
      <br v-if="visibleCarrusel1"/>
      <!-- <v-carousel
        cycle
        interval="20000"
        hide-delimiter-background 
        hide-delimiters
        height="470px"
        v-if="visibleCarrusel1"
      >
        <template v-for="(item, index) in carrusel1"> 
          <v-carousel-item 
            v-if="(index + 1) % columns === 1 || columns === 1" 
            :key="index"
          > 
            <v-row class="flex-nowrap" style="height:100%"> 
              <template v-for="(n,i) in columns"> 
                <template v-if="(+index + i) < carrusel1.length"> 
                  <v-col :key="i"> 
                    <v-sheet v-if="(+index + i) < carrusel1.length"
                      height="100%"
                    >
                      <v-row 
                        class="fill-height"
                        align="center"
                        justify="center"
                      >
                        <UnArticuloCarrusel 
                          :datosArticulo="carrusel1[+index + i]"
                          @asignarAlCarrito="agregarAlCarrito"
                        />
                      </v-row>
                    </v-sheet>
                  </v-col> 
                </template> 
              </template> 
            </v-row> 
          </v-carousel-item> 
        </template>
      </v-carousel> -->
      <div v-if="carrusel1.length > 0 && visibleCarrusel1 == true">
        <VueSlickCarousel 
          :slidesToShow="columns" 
          :draggable="true" 
          :slidesToScroll="1" 
          :swipeToSlide="true"
          :arrows="true"
          :autoplay="true"
          :autoplaySpeed="5000"
        >
          <template #prevArrow>
            <v-btn color="primary" fab fixed class="mx-md-n4 mx-4"><v-icon>mdi-arrow-left</v-icon></v-btn>
          </template>
          <template #nextArrow>
            <v-btn color="primary" fab fixed class="mx-md-n4 mx-4"><v-icon>mdi-arrow-right</v-icon></v-btn>
          </template>
          <div
            v-for="(carr, index) in carrusel1"
            :key="index"
          >
            <UnArticuloCarrusel 
              :datosArticulo="carr"
              @asignarAlCarrito="agregarAlCarrito"
            />
          </div>
        </VueSlickCarousel>
      </div>
      <!-- Banners 1 y 2 -->
      <!-- <v-container fluid> -->
        <v-row dense>
          <v-col
            v-for="(card, index) in banners1y2"
            :key="index"
            cols="6"
          >
            <v-hover
              v-slot="{ hover }"
              open-delay="200"
            >
              <v-card
                :href="card.redirect"
                target="_blank"
                :class="{ 'on-hover': hover }"
                :style="(index%2) == 1 ? 'margin-left: 8px' : 'margin-right: 8px'"
                :elevation="hover ? 16 : 2"
              >
                <!-- <v-img
                  :src="card.src"
                  v-bind:height="$vuetify.breakpoint.width > 960 ? '300px' : 'auto'"
                > -->
                <v-img
                  :src="card.src"
                  v-bind:height="$vuetify.breakpoint.width > 1904 ? '500px' : $vuetify.breakpoint.width > 960 ? '350px' : 'auto'"
                >
                </v-img>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
      <br/>
      <!-- </v-container> -->
      <!-- Carrusel 2 -->
      <v-toolbar
        height="50px"
        color="accent"
        dark
        v-if="visibleCarrusel2"
        flat
      >
        <v-toolbar-title class="formatoPalabras" style="color: #E41B41; font-size: x-large;"><strong>{{ tituloCarrusel2.toUpperCase() }}</strong></v-toolbar-title>
      </v-toolbar>
      <br v-if="visibleCarrusel2" />
      <!-- <v-carousel
        cycle
        interval="20000"
        hide-delimiter-background 
        hide-delimiters
        height="470px"
        v-if="visibleCarrusel2"
      >
        <template v-for="(item, index) in carrusel2"> 
          <v-carousel-item 
            v-if="(index + 1) % columns === 1 || columns === 1" 
            :key="index"
          > 
            <v-row class="flex-nowrap" style="height:100%"> 
              <template v-for="(n,i) in columns"> 
                <template v-if="(+index + i) < carrusel2.length"> 
                  <v-col :key="i"> 
                    <v-sheet v-if="(+index + i) < carrusel2.length"
                      height="100%"
                    >
                      <v-row 
                        class="fill-height"
                        align="center"
                        justify="center"
                      >
                        <UnArticuloCarrusel 
                          :datosArticulo="carrusel2[+index + i]"
                          @asignarAlCarrito="agregarAlCarrito"
                        />
                      </v-row>
                    </v-sheet>
                  </v-col> 
                </template> 
              </template> 
            </v-row> 
          </v-carousel-item> 
        </template>
      </v-carousel> -->
      <div v-if="carrusel2.length > 0 && visibleCarrusel2 == true">
        <VueSlickCarousel 
          :slidesToShow="columns" 
          :draggable="true" 
          :slidesToScroll="1" 
          :swipeToSlide="true"
          :arrows="true"
          :autoplay="true"
          :autoplaySpeed="5000"
        >
          <template #prevArrow>
            <v-btn color="primary" fab fixed class="mx-md-n4 mx-4"><v-icon>mdi-arrow-left</v-icon></v-btn>
          </template>
          <template #nextArrow>
            <v-btn color="primary" fab fixed class="mx-md-n4 mx-4"><v-icon>mdi-arrow-right</v-icon></v-btn>
          </template>
          <div
            v-for="(carr, index) in carrusel2"
            :key="index"
          >
            <UnArticuloCarrusel 
              :datosArticulo="carr"
              @asignarAlCarrito="agregarAlCarrito"
            />
          </div>
        </VueSlickCarousel>
      </div>
      <!-- Banners 3 y 4 -->
      <!-- <v-container fluid> -->
        <v-row dense>
          <v-col
            v-for="(card, index) in banners3y4"
            :key="index"
            cols="6"
          >
            <v-hover
              v-slot="{ hover }"
              open-delay="200"
            >
              <v-card
                :href="card.redirect"
                target="_blank"
                :class="{ 'on-hover': hover }"
                :elevation="hover ? 16 : 2"
                :style="(index%2) == 1 ? 'margin-left: 8px' : 'margin-right: 8px'"
              >
                <!-- <v-img
                  :src="card.src"
                  v-bind:height="$vuetify.breakpoint.width > 960 ? '300px' : 'auto'"
                > -->
                <v-img
                  :src="card.src"
                  v-bind:height="$vuetify.breakpoint.width > 1904 ? '500px' : $vuetify.breakpoint.width > 960 ? '350px' : 'auto'"
                >
                </v-img>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
      <!-- </v-container> -->
      <br/>
      <!-- Carrusel 3 -->
      <v-toolbar
        height="50px"
        color="accent"
        dark
        v-if="visibleCarrusel2"
        flat
      >
        <v-toolbar-title class="formatoPalabras" style="color: #E41B41; font-size: x-large;"><strong>{{ tituloCarrusel3.toUpperCase() }}</strong></v-toolbar-title>
      </v-toolbar>
      <br v-if="visibleCarrusel3" />
      <!-- <v-carousel
        
        interval="20000"
        hide-delimiter-background 
        hide-delimiters
        height="470px"
        v-if="visibleCarrusel3"
      >
        <template v-for="(item, index) in carrusel3"> 
          <v-carousel-item 
            v-if="(index + 1) % columns === 1 || columns === 1" 
            :key="index"
          > 
            <v-row class="flex-nowrap" style="height:100%"> 
              <template v-for="(n,i) in columns"> 
                <template v-if="(+index + i) < carrusel3.length"> 
                  <v-col :key="i"> 
                    <v-sheet v-if="(+index + i) < carrusel3.length"
                      height="100%"
                    >
                      <v-row 
                        class="fill-height"
                        align="center"
                        justify="center"
                      >
                        <UnArticuloCarrusel 
                          :datosArticulo="carrusel3[+index + i]"
                          @asignarAlCarrito="agregarAlCarrito"
                        />
                      </v-row>
                    </v-sheet>
                  </v-col> 
                </template> 
              </template> 
            </v-row> 
          </v-carousel-item> 
        </template>
      </v-carousel> -->
      <div v-if="carrusel3.length > 0 && visibleCarrusel3 == true">
        <VueSlickCarousel 
          :slidesToShow="columns" 
          :draggable="true" 
          :slidesToScroll="1" 
          :swipeToSlide="true"
          :arrows="true"
          :autoplay="true"
          :autoplaySpeed="5000"
        >
          <template #prevArrow>
            <v-btn color="primary" fab fixed class="mx-md-n4 mx-4"><v-icon>mdi-arrow-left</v-icon></v-btn>
          </template>
          <template #nextArrow>
            <v-btn color="primary" fab fixed class="mx-md-n4 mx-4"><v-icon>mdi-arrow-right</v-icon></v-btn>
          </template>
          <div
            v-for="(carr, index) in carrusel3"
            :key="index"
          >
            <UnArticuloCarrusel 
              :datosArticulo="carr"
              @asignarAlCarrito="agregarAlCarrito"
            />
          </div>
        </VueSlickCarousel>
      </div>
      <!-- Banners 5 y 6 -->
      <v-row dense>
        <v-col
          v-for="(card, index) in banners5y6"
          :key="index"
          cols="6"
        >
          <v-hover
            v-slot="{ hover }"
            open-delay="200"
          >
            <v-card
              :href="card.redirect"
              target="_blank"
              :class="{ 'on-hover': hover }"
              :elevation="hover ? 16 : 2"
              :style="(index%2) == 1 ? 'margin-left: 8px' : 'margin-right: 8px'"
            >
              <v-img
                :src="card.src"
                v-bind:height="$vuetify.breakpoint.width > 1904 ? '500px' : $vuetify.breakpoint.width > 960 ? '350px' : 'auto'"
              >
              </v-img>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
      <!-- Loading -->
      <Loading :activo="dialogCarga"/>
    </v-container>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import Loading from '../../components/genericos/Loading'
import UnArticuloCarrusel from '../index/UnArticuloCarrusel'
import config from '../../../config'
import LoadingCircular from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import VueSlickCarousel from 'vue-slick-carousel'
export default {
  data() {
    return {
      items: [],
      dialogCarga: false,
      cards: [
        {src: '/img/index/celulares.webp', flex: 4},
        {src: '/img/index/accesorios.webp', flex: 4}
      ],
      bannersInicio: [],
      tituloCarrusel1: '',
      tituloCarrusel2: '',
      tituloCarrusel3: '',
      visibleCarrusel1: true,
      visibleCarrusel2: true,
      visibleCarrusel3: true,
      intentosLoginJarvis: 0,
      carrusel1: [],
      carrusel2: [],
      carrusel3: [],
      settings: {
        dots: false,
        infinite: true,
        initialSlide: 1,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        swipeToSlide: true
      }
    }
  },
  created(){
    this.iniciarContenido()
  },
  components: {
    Loading,
    UnArticuloCarrusel,
    LoadingCircular,
    VueSlickCarousel
  },
  computed: {
    ...mapState(['respBanner', 'respArticulosEcommerce', 'articulosCarrito', 'respConfigCarruseles', 'loadingCarga',
                'datosUsuario', 'respDatosApiJarvis']),
    columns() {
      if (this.$vuetify.breakpoint.xl) {
        return 6;
      }

      if (this.$vuetify.breakpoint.lg) {
        return 5;
      }

      if (this.$vuetify.breakpoint.md) {
        return 5;
      }

      if (this.$vuetify.breakpoint.sm) { 
        return 4;
      }

      return 1;
    },
    banners1y2(){
      return this.bannersInicio.filter(element => parseInt(element.orden) === 1 || parseInt(element.orden) === 2)
    },
    banners3y4(){
      return this.bannersInicio.filter(element => parseInt(element.orden) === 3 || parseInt(element.orden) === 4)
    },
    banners5y6(){
      return this.bannersInicio.filter(element => parseInt(element.orden) === 5 || parseInt(element.orden) === 6)
    }
  },
  methods: {
    ...mapActions(['getBanners', 'getImagenesMini', 'pushArtCarrito', 'loadCarrito', 'getConfigCarruseles']),
    async iniciarContenido(){
      this.dialogCarga = true
      await this.getBanners()
    },
    async getCriteriosCarruseles(){
      await this.getConfigCarruseles()
    },
    async getImagenesArticulos(configCarruseles, criterios, articulos){
      await this.getImagenesMini({configCarruseles: configCarruseles, criterios: criterios, articulos: articulos})
    },
    async iniciarCarrito(){
      await this.loadCarrito()
    },
    async getArrayCarruselCategoria(categoria, opcion){
      let listado = []
      if (Object.keys(this.respArticulosEcommerce).length > 0){
        let lisPeticion = await this.$store.dispatch('articulos/getArrayArticulos', {articulos: this.respArticulosEcommerce.articulos, opcion: opcion, categoria: categoria, imgGrandes: 0})
        if (lisPeticion.resultado == 0){
          this.dialogCarga = false
          this.$store.dispatch('executeSnackBar', {msj: lisPeticion.msj, color: 'error', activo: true})
          return
        }
        // asigno el listado
        listado = lisPeticion.listado
      }
      return listado
    },
    async getArrayCarruselNueProd(){
      let listado = []
      if (Object.keys(this.respArticulosEcommerce).length > 0){
        let artsPeticion = await this.$store.dispatch('articulos/getArrayArticulos', {articulos: this.respArticulosEcommerce.articulos, opcion: 3, categoria: 0, imgGrandes: 0})
        if (artsPeticion.resultado == 0){
          this.dialogCarga = false
          this.$store.dispatch('executeSnackBar', {msj: artsPeticion.msj, color: 'error', activo: true})
          return
        }
        listado = artsPeticion.listado
      }

      return listado
    },
    async agregarAlCarrito(articuloSeleccionado){
      // articuloSeleccionado debe ser un objeto
      if (Object.keys(articuloSeleccionado).length > 0){
        // ya se encuentra en el carrito? las cantidades son editables, por eso no busco exactamente el objeto
        if (this.articulosCarrito.length > 0){
          let cont = 0
          for (let id in this.articulosCarrito){
            if (this.articulosCarrito[id].articuloKey == articuloSeleccionado.articuloKey){
              cont = cont + 1
            }
          }
          if (parseInt(cont) === 0){
            await this.pushArtCarrito(articuloSeleccionado)
            this.$store.dispatch('executeSnackBar', {msj: 'Artículo Agregado al Carrito', color: 'success', activo: true})
          }else{
            // ya se encuentra en el carrito
            this.$store.dispatch('executeSnackBar', {msj: 'El Artículo seleccionado ya se encuentra en el Carrito', color: 'warning', activo: true})
          }
        }else{
          await this.pushArtCarrito(articuloSeleccionado)
          this.$store.dispatch('executeSnackBar', {msj: 'Artículo Agregado al Carrito', color: 'success', activo: true})
        }
      }
    },
    async getCarruseles(){
      this.carrusel1 = []
      this.carrusel2 = []
      this.carrusel3 = []
      if (Object.keys(this.respConfigCarruseles).length > 0){
        // primer carrusel del inicio, tomo la configuracion del elemento que tenga orden 1
        let config1 = this.respConfigCarruseles.configCarruseles.filter(element => parseInt(element.orden) === 1)
        let config2 = this.respConfigCarruseles.configCarruseles.filter(element => parseInt(element.orden) === 2)
        let config3 = this.respConfigCarruseles.configCarruseles.filter(element => parseInt(element.orden) === 3)
        if (config1.length > 0){
          this.tituloCarrusel1 = config1[0].nombre
          // consigo el criterio por el que esta configurado
          let criterio = this.respConfigCarruseles.criterios.filter(element => parseInt(element.key_firebase) === parseInt(config1[0].criterio))
          if (criterio.length > 0){
            // busco algo que esta en el primer nivel de la estructura de articulos?
            if (criterio[0].criterio.campo_padre.toUpperCase() == criterio[0].criterio.campo_hijo.toUpperCase()){
              // es algo que esta en el primer nivel de la estructura de articulos
              if (criterio[0].criterio.campo_padre.toUpperCase() == 'NUEVO_PRODUCTO'){
                this.carrusel1 = this.getArrayCarruselNueProd()
              }
            }else{
              // es algo que esta dentro de una coleccion de la estructura de articulos
              if (criterio[0].criterio.campo_padre.toUpperCase() == 'CAT_ECOMMERCE'){
                // carrusel por categorias, pero de cual?
                if (criterio[0].criterio.campo_hijo.toUpperCase() == 'IDPADRE'){
                  // carrusel por categorias padre
                  this.carrusel1 = await this.getArrayCarruselCategoria(parseInt(criterio[0].criterio.valor_campo), 1)
                }else{
                  // carrusel por categorias hijo
                  this.carrusel1 = await this.getArrayCarruselCategoria(parseInt(criterio[0].criterio.valor_campo), 2)
                }
              }
            }
          }
        }else{
          this.visibleCarrusel1 = false
        }
        if (config2.length > 0){
          this.tituloCarrusel2 = config2[0].nombre
          // consigo el criterio por el que esta configurado
          let criterio = this.respConfigCarruseles.criterios.filter(element => parseInt(element.key_firebase) === parseInt(config2[0].criterio))
          if (criterio.length > 0){
            // busco algo que esta en el primer nivel de la estructura de articulos?
            if (criterio[0].criterio.campo_padre.toUpperCase() == criterio[0].criterio.campo_hijo.toUpperCase()){
              // es algo que esta en el primer nivel de la estructura de articulos
              if (criterio[0].criterio.campo_padre.toUpperCase() == 'NUEVO_PRODUCTO'){
                this.carrusel2 = this.getArrayCarruselNueProd()
              }
            }else{
              // es algo que esta dentro de una coleccion de la estructura de articulos
              if (criterio[0].criterio.campo_padre.toUpperCase() == 'CAT_ECOMMERCE'){
                // carrusel por categorias, pero de cual?
                if (criterio[0].criterio.campo_hijo.toUpperCase() == 'IDPADRE'){
                  // carrusel por categorias padre
                  this.carrusel2 = await this.getArrayCarruselCategoria(parseInt(criterio[0].criterio.valor_campo), 1)
                }else{
                  // carrusel por categorias hijo
                  this.carrusel2 = await this.getArrayCarruselCategoria(parseInt(criterio[0].criterio.valor_campo), 2)
                }
              }
            }
          }
        }else{
          this.visibleCarrusel2 = false
        }
        if (config3.length > 0){
          this.tituloCarrusel3 = config3[0].nombre
          // consigo el criterio por el que esta configurado
          let criterio = this.respConfigCarruseles.criterios.filter(element => parseInt(element.key_firebase) === parseInt(config3[0].criterio))
          if (criterio.length > 0){
            // busco algo que esta en el primer nivel de la estructura de articulos?
            if (criterio[0].criterio.campo_padre.toUpperCase() == criterio[0].criterio.campo_hijo.toUpperCase()){
              // es algo que esta en el primer nivel de la estructura de articulos
              if (criterio[0].criterio.campo_padre.toUpperCase() == 'NUEVO_PRODUCTO'){
                this.carrusel3 = await this.getArrayCarruselNueProd()
              }
            }else{
              // es algo que esta dentro de una coleccion de la estructura de articulos
              if (criterio[0].criterio.campo_padre.toUpperCase() == 'CAT_ECOMMERCE'){
                // carrusel por categorias, pero de cual?
                if (criterio[0].criterio.campo_hijo.toUpperCase() == 'IDPADRE'){
                  // carrusel por categorias padre
                  this.carrusel3 = await this.getArrayCarruselCategoria(parseInt(criterio[0].criterio.valor_campo), 1)
                }else{
                  // carrusel por categorias hijo
                  this.carrusel3 = await this.getArrayCarruselCategoria(parseInt(criterio[0].criterio.valor_campo), 2)
                }
              }
            }
          }
        }else{
          this.visibleCarrusel3 = false
        }
      }
    }
  },
  watch: {
    respBanner: function(){
      if (Object.keys(this.respBanner).length > 0){
        if (parseInt(this.respBanner.resultado) === 1){
          this.items = this.respBanner.banners.bannersCarrusel
          this.bannersInicio = this.respBanner.banners.bannersInicio
        }
        this.getCriteriosCarruseles()
      }
    },
    respConfigCarruseles: function(){
      if (Object.keys(this.respConfigCarruseles).length > 0){
        if (parseInt(this.respConfigCarruseles.resultado) === 1){
          this.getImagenesArticulos(this.respConfigCarruseles.configCarruseles, this.respConfigCarruseles.criterios, this.respConfigCarruseles.articulos)
        }else{
          console.log(this.respConfigCarruseles.msj)
          this.dialogCarga = false
        }
      }
    },
    respArticulosEcommerce: async function(){
      this.dialogCarga = false
      if (Object.keys(this.respArticulosEcommerce).length > 0){
        if (parseInt(this.respArticulosEcommerce.resultado) === 1){
          // armamos los array que necesitamos para los carruseles
          this.getCarruseles()
        }
        // inicio mi carrito del LocalStorage
        await this.iniciarCarrito()
      }
    },
    datosUsuario: function(){
      if (Object.keys(this.datosUsuario).length > 0){
        if (this.datosUsuario.hasOwnProperty('resultado')){
          if (parseInt(this.datosUsuario.resultado) === 1){
            // es nuevo registro o login normal?
            if (parseInt(this.datosUsuario.tipo) === 1){
              // login normal
              Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Inicio de Sesión',
                text: 'Su Inicio de Sesión fue exitoso, espere a ser redireccionado',
                showConfirmButton: false,
                timer: 3000
              })
            }else if (parseInt(this.datosUsuario.tipo) === 2){
              // registro
              Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Nuevo Registro',
                text: 'Su registro fue exitoso, espere a ser redireccionado',
                showConfirmButton: false,
                timer: 3000
              })
            }else{
              // hizo logout
              setTimeout(() => [location.reload()], 2000)
            }
          }else{
            if (parseInt(this.datosUsuario.tipo) === 1){
              Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Inicio de Sesión',
                text: this.datosUsuario.msj,
                showConfirmButton: false,
                timer: 3000
              })
            }else if (parseInt(this.datosUsuario.tipo) === 2){
              Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Nuevo Registro',
                text: this.datosUsuario.msj,
                showConfirmButton: false,
                timer: 3000
              })
            }else{
              // hizo logout
              setTimeout(() => [location.reload()], 2000)
            }
          }
        }
      }
    },
    respDatosApiJarvis: function(){
      if (Object.keys(this.respDatosApiJarvis).length > 0){
        if (parseInt(this.respDatosApiJarvis.resultado) === 1){
          if (parseInt(this.intentosLoginJarvis) <= 3){
            this.intentosLoginJarvis = this.intentosLoginJarvis + 1
            // iniciar el formulario nuevamente
            this.iniciarContenido()
          }else{
            console.log(this.respDatosApiJarvis.msj)
            this.dialogCarga = false
          }
        }
      }
    }
  },
}
</script>

<style scoped>
  .otroFormato{
    font-family: 'Poppins', sans-serif;
  }
  .v-card.on-hover.theme--dark{
    background-color: rgba(#FFF, 0.8)
  }
  .v-card__text{
    color: #000
  }
</style>