<template>
  <div>
    <v-container>
      <!-- Encabezado del Articulo -->
      <v-row class="ma-0 pa-0">
        <!-- Galeria de Fotos -->
        <v-col 
          cols="12"
          xs="12"
          sm="12"
          md="6"
          lg="6"
          xl="6"
        >
          <v-card
            id="articulosVisualizacion" 
          >
            <swiper 
              :options="swiperOptionTop" 
              class="gallery-top" 
              ref="swiperTop"
            >
              <swiper-slide 
                v-for="image in unArticuloDetallado.articuloImagenes" 
                :key="image.src" 
                class="slide-img"
              >
                <v-card flat>
                  <v-img
                    :src="image.src"
                  ></v-img>
                </v-card>
              </swiper-slide>
              <div class="swiper-button-next" slot="button-next" @click="nextSlide"></div>
              <div class="swiper-button-prev" slot="button-prev" @click="prevSlide"></div>
            </swiper>
            <v-divider></v-divider>
            <swiper 
              :options="swiperOptionThumbs" 
              class="gallery-thumbs" 
              ref="swiperThumbs"
            >
              <swiper-slide 
                v-for="(image, index) in unArticuloDetallado.articuloImagenes" 
                :key="index" 
                class="slide-img"
              >
                <v-card>
                  <v-img
                    :src="image.src"
                    @click="moverSlide(index)"
                  ></v-img>
                </v-card>
              </swiper-slide>
            </swiper>
          </v-card>
        </v-col>
        <!-- Nombre, Precio, Financiaciones del Articulo -->
        <v-col 
          cols="12"
          xs="12"
          sm="12"
          md="6"
          lg="6"
          xl="6"
        >
          <v-card
            flat
            color="accent"
          >
            <v-card-title class="formatoPalabras pt-0" style="word-break: normal;">
              <!-- <h1 style="margin: 5px"><strong>{{ unArticuloDetallado.articuloNombre.toUpperCase() }}</strong></h1> -->
              <p style="font-size: 40px; line-height: 45px;"><strong>{{ unArticuloDetallado.articuloNombre.toUpperCase() }}</strong></p>
            </v-card-title>
            <v-card-text class="pt-0 mt-0">
              <div v-if="unArticuloDetallado.articuloDescripCorta.length <= 450">
                <p align="justify" style="font-size: 16px; margin: 0px; color: black" class="formatoPalabras">{{unArticuloDetallado.articuloDescripCorta}}</p>
              </div>
              <div v-else>
                <p align="justify" style="font-size: 16px; margin: 0px; color: black" class="formatoPalabras">{{unArticuloDetallado.articuloDescripCorta.substring(0, 447) + '...'}} <v-btn text small color="blue">Ver más</v-btn></p>
              </div>
              <!-- <br/>
              <h3 style="color: #E41B41">
                <span>EN STOCK</span>
              </h3> -->
              <br/>
              <v-row style="padding-left: 10px" :justify=" movil ? 'center' : 'start'">
                <h3 style="color: #E41B41; padding-top: 6px"><span>CANTIDAD: </span></h3>
                <v-btn title="Disminuir 1 unidad" icon @click="quitarCantidadArticulo()"><v-icon>mdi-minus-circle-outline</v-icon></v-btn>
                <input type="text" v-model="unArticuloDetallado.cantidad" readonly class="text-center" style="width: 30px;">
                <v-btn title="Aumentar 1 unidad" icon @click="agregarCantidadArticulo()"><v-icon>mdi-plus-circle-outline</v-icon></v-btn>
              </v-row>
              <br/>
              <p style="font-size: 20px; color: black" :class="movil ? 'formatoPalabras mb-2 text-center' : 'formatoPalabras mb-2'">Precio del Producto:</p>
              <h1 style="color: #E41B41" :class="movil ? 'text-center' : 'text-left'">
                ${{ Intl.NumberFormat("de-DE").format(unArticuloDetallado.articuloPrecio) }}
              </h1>
            </v-card-text>
              <v-col cols="12" :class="movil ? 'text-center' : 'text-left'">
                <v-btn
                  color="primary"
                  dark
                  class="formatoPalabras"
                  width="150px"
                  :outlined="showCarrito"
                  @mouseenter="mostrarCarrito"
                  @mouseleave="ocultarCarrito"
                  @click.stop="agregarCarrito(unArticuloDetallado)"
                  rounded
                >
                  Agregar
                  <v-icon
                    dark
                    v-if="showCarrito"
                  >
                    mdi-cart-outline
                  </v-icon> 
                </v-btn>
              </v-col>
          </v-card>
        </v-col>
      </v-row>
      <br/>
      <!-- Especificaciones del Articulo -->
      <v-row dense>
        <v-col
        cols="12"
        >
          <div class="text-center formatoPalabras"><h1>Especificaciones Técnicas</h1></div>
        </v-col>
        <v-col
          cols="12"
          xs="12"
          sm="12"
          md="6"
          lg="6"
          xl="6"
        >
          <v-card-text class="formatoPalabras">
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr
                    v-for="item in caracteristicas1"
                    :key="item.name"
                    style="background-color: #f2f2f2"
                  >
                    <td><strong>{{ item.campo_det }}</strong></td>
                    <td>{{ item.valor_det }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-col>
        <v-col
          cols="12"
          xs="12"
          sm="12"
          md="6"
          lg="6"
          xl="6"
        >
          <v-card-text class="formatoPalabras">
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr
                    v-for="item in caracteristicas2"
                    :key="item.name"
                    style="background-color: #f2f2f2"
                  >
                    <td><strong>{{ item.campo_det }}</strong></td>
                    <td>{{ item.valor_det }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-col>
      </v-row>
      <!-- Carrusel de Articulos Asociados -->
      <v-toolbar
        height="50px"
        color="accent"
        dark
        class="formatoPalabras"
        flat
      >
        <v-toolbar-title style="color: #E41B41; font-size: x-large;"><strong>ARTÍCULOS RELACIONADOS</strong></v-toolbar-title>
      </v-toolbar>
      <br/>
      <!-- <v-carousel
        cycle
        interval="20000"
        hide-delimiter-background 
        hide-delimiters
        height="470px"
      >
        <template v-for="(item, index) in itemsArtsAsociados"> 
          <v-carousel-item 
            v-if="(index + 1) % columns === 1 || columns === 1" 
            :key="index"
          > 
            <v-row class="flex-nowrap" style="height:100%"> 
              <template v-for="(n,i) in columns"> 
                <template v-if="(+index + i) < itemsArtsAsociados.length"> 
                  <v-col :key="i"> 
                    <v-sheet v-if="(+index + i) < itemsArtsAsociados.length"
                      height="100%"
                    >
                      <v-row 
                        class="fill-height"
                        align="center"
                        justify="center"
                      >
                        <UnArticuloCarrusel 
                          :datosArticulo="itemsArtsAsociados[+index + i]"
                          @asignarAlCarrito="agregarCarrito"
                        />
                      </v-row>
                    </v-sheet>
                  </v-col> 
                </template> 
              </template> 
            </v-row> 
          </v-carousel-item> 
        </template>
      </v-carousel> -->
      <div v-if="itemsArtsAsociados.length > 0">
        <VueSlickCarousel 
          :slidesToShow="columns" 
          :draggable="true" 
          :slidesToScroll="1" 
          :swipeToSlide="true"
          :arrows="true"
          :autoplay="true"
          :autoplaySpeed="5000"
        >
          <template #prevArrow>
            <v-btn color="primary" fab fixed class="mx-md-n4 mx-4"><v-icon>mdi-arrow-left</v-icon></v-btn>
          </template>
          <template #nextArrow>
            <v-btn color="primary" fab fixed class="mx-md-n4 mx-4"><v-icon>mdi-arrow-right</v-icon></v-btn>
          </template>
          <div
            v-for="(carr, index) in itemsArtsAsociados"
            :key="index"
          >
            <UnArticuloCarrusel 
              :datosArticulo="carr"
              @asignarAlCarrito="agregarCarrito"
            />
          </div>
        </VueSlickCarousel>
      </div>
    </v-container>
    <!-- Loading -->
    <Loading :activo="dialogCarga" />
    <!-- Loading -->
    <LoadingCircular 
      :active.sync="loadingCarga" 
      :can-cancel="true"   
      color="#ed1841"         
      oader="dots"
      background-color="#252527"
      :is-full-page="true">
    </LoadingCircular>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import Loading from '../genericos/Loading.vue'
import UnArticuloCarrusel from '../index/UnArticuloCarrusel.vue'
import LoadingCircular from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import VueSlickCarousel from 'vue-slick-carousel'
export default {
  data(){
    return{
      dialogCarga: false,
      unArticuloDetallado: {
        articuloNombre: '',
        articuloRubro: '',
        articuloCategoria: '',
        articuloPrecio: 0,
        articuloDescuento: 0,
        articuloNuevo: 0,
        articuloKey: '',
        precioNeto: '',
        articuloMarcaCodigo: '',
        articuloModeloCodigo: '',
        articuloCatCodigo: '',
        articuloCaracteristicas: [],
        articuloDescripCorta: '',
        cantidad: 1,
        articuloImagenes: []
      },
      swiperOptionTop: {
        initialSlide: 0,
        spaceBetween: 20,
        allowTouchMove:true
      },
      swiperOptionThumbs: {
        initialSlide: 0,
        spaceBetween: 20,
        centeredSlides: true,
        slidesPerView: 5,
        slideToClickedSlide: true,
        allowTouchMove: true
      },
      showCarrito: false,
      posSlide: 0,
      caracteristicas1: [],
      caracteristicas2: [],
      itemsArtsAsociados: [],
      movil: false
    }
  },
  mounted(){
    this.swiper.slideTo(this.posSlide, 1000, false)
    this.swiperGaleria.slideTo(this.posSlide,1000, false)
  },
  created(){
    this.iniciarCarrito()
    this.isMovil()
  },
  components:{
    Loading,
    UnArticuloCarrusel,
    LoadingCircular,
    VueSlickCarousel
  },
  computed:{
    ...mapState(['articulosCarrito', 'respUnArticulo', 'respArticulosAsociados', 'loadingCarga', 'datosUsuario']),
    swiper() {
      // asigno el evento por cada vez que arrastran la galeria de fotos
      this.$refs.swiperTop.$swiper.on('slideChange', (args) => {
        this.moverSlide(args.activeIndex)
      })
      return this.$refs.swiperTop.$swiper
    },
    swiperGaleria(){
      // asigno el evento por cada vez que arrastran la galeria de fotos
      this.$refs.swiperThumbs.$swiper.on('slideChange', (args) => {
        this.moverSlide(args.activeIndex)
      })
      return this.$refs.swiperThumbs.$swiper
    },
    columns() {
      if (this.$vuetify.breakpoint.xl) {
        return 6;
      }

      if (this.$vuetify.breakpoint.lg) {
        return 5;
      }

      if (this.$vuetify.breakpoint.md) {
        return 5;
      }

      if (this.$vuetify.breakpoint.sm) {
        return 4;
      }

      return 1;
    }
  },
  methods:{
    ...mapActions(['pushArtCarrito', 'loadCarrito', 'getOneArticleInfo', 'getCarritoAsociado']),
    async iniciarCarrito(){
      this.dialogCarga = true
      await this.loadCarrito()
    },
    async getInfoArticulo(){
      await this.getOneArticleInfo(this.$route.params.keyFirebase)
    },
    prevSlide(){
      let pos = 0
      if (parseInt(this.$refs.swiperTop.$swiper.activeIndex) === 0){
        pos = this.unArticuloDetallado.articuloImagenes.length - 1
      }else{
        pos = this.$refs.swiperTop.$swiper.activeIndex - 1
      }
      this.swiper.slideTo(pos, 500, false)
      this.swiperGaleria.slideTo(pos,500, false)
      /*this.$refs.swiperTop.$swiper.slideNext(this.$refs.swiperTop.$swiper.activeIndex)
      this.$refs.swiperThumbs.$swiper.slideNext(this.$refs.swiperTop.$swiper.activeIndex)*/
    },
    nextSlide(){
      let pos = 0
      if (parseInt(this.$refs.swiperTop.$swiper.activeIndex) === parseInt(this.unArticuloDetallado.articuloImagenes.length - 1)){
        pos = 0
      }else{
        pos = this.$refs.swiperTop.$swiper.activeIndex + 1
      }
      this.swiper.slideTo(pos, 500, false)
      this.swiperGaleria.slideTo(pos,500, false)
      /*this.$refs.swiperTop.$swiper.slidePrev(this.$refs.swiperTop.$swiper.activeIndex)
      this.$refs.swiperThumbs.$swiper.slidePrev(this.$refs.swiperTop.$swiper.activeIndex)*/
    },
    moverSlide(index){
      this.swiper.slideTo(index, 500, false)
      this.swiperGaleria.slideTo(index, 500, false)
    },
    mostrarCarrito(){
      this.showCarrito = true
    },
    ocultarCarrito(){
      this.showCarrito = false
    },
    async agregarCarrito(articuloSeleccionado){
      // articuloSeleccionado debe ser un objeto
      if (Object.keys(articuloSeleccionado).length > 0){
        // ya se encuentra en el carrito? las cantidades son editables, por eso no busco exactamente el objeto
        if (this.articulosCarrito.length > 0){
          let cont = 0
          for (let id in this.articulosCarrito){
            if (this.articulosCarrito[id].articuloKey == articuloSeleccionado.articuloKey){
              cont = cont + 1
            }
          }
          if (parseInt(cont) === 0){
            await this.pushArtCarrito(articuloSeleccionado)
            this.$store.dispatch('executeSnackBar', {msj: 'Artículo Agregado al Carrito', color: 'success', activo: true})
          }else{
            // ya se encuentra en el carrito
            this.$store.dispatch('executeSnackBar', {msj: 'El Artículo seleccionado ya se encuentra en el Carrito', color: 'warning', activo: true})
          }
        }else{
          await this.pushArtCarrito(articuloSeleccionado)
          this.$store.dispatch('executeSnackBar', {msj: 'Artículo Agregado al Carrito', color: 'success', activo: true})
        }
      }
    },
    quitarCantidadArticulo(){
      if (parseInt(this.unArticuloDetallado.cantidad) > 1){
        this.unArticuloDetallado.cantidad = parseInt(this.unArticuloDetallado.cantidad) - 1
        let precioAnterior = this.unArticuloDetallado.articuloPrecio
        this.unArticuloDetallado.precioNeto = parseFloat(this.unArticuloDetallado.cantidad * precioAnterior).toFixed(2)
      }
    },
    agregarCantidadArticulo(){
      this.unArticuloDetallado.cantidad = parseInt(this.unArticuloDetallado.cantidad) + 1
      let precioAnterior = this.unArticuloDetallado.articuloPrecio
      this.unArticuloDetallado.precioNeto = parseFloat(this.unArticuloDetallado.cantidad * precioAnterior).toFixed(2)
    },
    toTop(){
      this.$vuetify.goTo(0)
    },
    reloadPage(){
      window.location.reload()
    },
    isMovil(){
			var mobile = {
        Android: function() {
          return navigator.userAgent.match(/Android/i);
        },
        BlackBerry: function() {
          return navigator.userAgent.match(/BlackBerry/i);
        },
        iOS: function() {
          return navigator.userAgent.match(/iPhone|iPad|iPod/i);
        },
        Opera: function() {
          return navigator.userAgent.match(/Opera Mini/i);
        },
        Windows: function() {
          return navigator.userAgent.match(/IEMobile/i);
        },
        any: function() {
          return (mobile.Android() || mobile.BlackBerry() || mobile.iOS() || mobile.Opera() || mobile.Windows());
        }
      }
      // controlo la salida de mobile
      if (Array.isArray(mobile.any())){
        if (mobile.any().length > 0){
          this.movil = true
        }
      }
    },
  },
  watch:{
    articulosCarrito: function(){
      this.getInfoArticulo()
    },
    respUnArticulo: async function(){
      if (Object.keys(this.respUnArticulo).length > 0){
        if (parseInt(this.respUnArticulo.resultado) === 1){
          let unListado = []
          unListado.push(this.respUnArticulo.articulo)
          let artsPeticion = await this.$store.dispatch('articulos/getArrayArticulos', {articulos: unListado, opcion: 0, categoria: 0, imgGrandes: 1})
          if (artsPeticion.resultado == 0){
            this.dialogCarga = false
            this.$store.dispatch('executeSnackBar', {msj: artsPeticion.msj, color: 'error', activo: true})
            return
          }
          // asigno
          this.unArticuloDetallado = artsPeticion.listado[0]
          this.unArticuloDetallado.articuloKey = this.$route.params.keyFirebase
          // armo mi filtro para armar el carrito asociado
          let filtros = []
          let filtro = {
            marca: parseInt(artsPeticion.listado[0].articuloMarcaCodigo),
            modelo: parseInt(artsPeticion.listado[0].articuloModeloCodigo),
            categoria: parseInt(artsPeticion.listado[0].articuloCatCodigo)
          }
          filtros.push(filtro)
          this.getCarritoAsociado(filtros)
        }
      }
      this.toTop()
    },
    unArticuloDetallado: function(){
      if (this.unArticuloDetallado.articuloImagenes.length > 0){
        // posiciono mi galeria a la mitad de las imagenes
        this.posSlide = Math.trunc(this.unArticuloDetallado.articuloImagenes.length/2)
        this.swiper.slideTo(this.posSlide, 1000, false)
        this.swiperGaleria.slideTo(this.posSlide,1000, false)
        this.swiperOptionTop.initialSlide = parseInt(this.posSlide)
        this.swiperOptionThumbs.initialSlide = parseInt(this.posSlide)
      }
      if (this.unArticuloDetallado.articuloCaracteristicas.length > 0){
        this.caracteristicas1 = []
        this.caracteristicas2 = []
        // formo mis dos array de caracteristicas
        for (let id = 0; id <= Math.trunc((this.unArticuloDetallado.articuloCaracteristicas.length - 1)/2); id++){
          this.caracteristicas1.push(this.unArticuloDetallado.articuloCaracteristicas[id])
        }
        for (let id = Math.trunc((this.unArticuloDetallado.articuloCaracteristicas.length - 1)/2) + 1; id <= this.unArticuloDetallado.articuloCaracteristicas.length - 1; id++){
          this.caracteristicas2.push(this.unArticuloDetallado.articuloCaracteristicas[id])
        }
      }
      this.toTop()
    },
    respArticulosAsociados: async function(){
      this.dialogCarga = false
      if (Object.keys(this.respArticulosAsociados).length > 0){
        if (parseInt(this.respArticulosAsociados.resultado) === 1){
          this.itemsArtsAsociados = []
          let artsPeticion = await this.$store.dispatch('articulos/getArrayArticulos', {articulos: this.respArticulosAsociados.articulos, opcion: 0, categoria: 0, imgGrandes: 0})
          if (artsPeticion.resultado == 0){
            this.dialogCarga = false
            this.$store.dispatch('executeSnackBar', {msj: artsPeticion.msj, color: 'error', activo: true})
            return
          }
          this.itemsArtsAsociados = artsPeticion.listado
        }
      }
      this.toTop()
    },
    $route:{
      deep:true,
      handler(to,from){
        if (to.name == from.name && to.path !== from.path){
          this.reloadPage()
        }
      }
    },
    datosUsuario: function(){
      if (Object.keys(this.datosUsuario).length > 0){
        if (this.datosUsuario.hasOwnProperty('resultado')){
          if (parseInt(this.datosUsuario.resultado) === 1){
            // es nuevo registro o login normal?
            if (parseInt(this.datosUsuario.tipo) === 1){
              // login normal
              Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Inicio de Sesión',
                text: 'Su Inicio de Sesión fue exitoso, espere a ser redireccionado',
                showConfirmButton: false,
                timer: 3000
              })
            }else if (parseInt(this.datosUsuario.tipo) === 2){
              // registro
              Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Nuevo Registro',
                text: 'Su registro fue exitoso, espere a ser redireccionado',
                showConfirmButton: false,
                timer: 3000
              })
            }else{
              // hizo logout
              setTimeout(() => [location.reload()], 2000)
            }
          }else{
            if (parseInt(this.datosUsuario.tipo) === 1){
              Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Inicio de Sesión',
                text: this.datosUsuario.msj,
                showConfirmButton: false,
                timer: 3000
              })
            }else if (parseInt(this.datosUsuario.tipo) === 2){
              Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Nuevo Registro',
                text: this.datosUsuario.msj,
                showConfirmButton: false,
                timer: 3000
              })
            }else{
              // hizo logout
              setTimeout(() => [location.reload()], 2000)
            }
          }
        }
      }
    }
  },
}
</script>
  
<style>
  .gallery-top {
    height: 64% !important;
    width: 80%;
  }
  .gallery-thumbs {
    height: 20% !important;
    width: 80%;
  }
  .gallery-thumbs .swiper-slide {
    width: 50px;
    height: auto;
    opacity: 0.4;
  }
  .gallery-thumbs .swiper-slide-active {
    opacity: 1;
  }
  .imgGaleria {
    width: inherit;
    height: inherit;
  }
  .swiper-button-prev {
    background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'><path d='M0,22L22,0l2.1,2.1L4.2,22l19.9,19.9L22,44L0,22L0,22L0,22z' fill='#E78127'/></svg>") !important;
  }
  .swiper-button-next {
    background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'><path d='M27,22L27,22L5,44l-2.1-2.1L22.8,22L2.9,2.1L5,0L27,22L27,22z' fill='#E78127'/></svg>") !important;
  }
  #articulosVisualizacion {
    max-width: initial;
  }
  .formatoPalabras{
    font-family: 'Poppins', sans-serif;
  }
</style>