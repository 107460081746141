<template>
  <v-container>
    <v-hover
      v-slot="{ hover }"
      open-delay="200"
    >
      <v-card 
        max-width="200px" 
        height="400px" 
        class="mx-auto"
        :class="{ 'on-hover': hover }"
        @click.stop="verArticuloDetalle($event)"
        @mousedown.middle="abrirNuevaPestaña()"
        flat
        outlined
      >
        <!-- :elevation="hover ? 16 : 2" esto iba en el v-card que esta arriba -->
        <!-- Chip Descuento -->
        <!-- <v-row style="height: 40px;">
          <v-chip
            class="ma-3 formatoPalabras"
            color="green"
            v-if="parseInt(datosArticulo.articuloDescuento) > 0"
            outlined
          >
            <strong>-{{ parseInt(datosArticulo.articuloDescuento) }}%</strong>
          </v-chip>
          <v-btn small text v-if="parseInt(datosArticulo.articuloDescuento) > 0" class="ml-2 mt-4 formatoPalabras" color="green">
            <strong style="font-size: 16px">-{{ parseInt(datosArticulo.articuloDescuento) }}%</strong>
          </v-btn>
          <v-btn
            class="formatoPalabras ml-3 mt-4"
            fab
            dark
            color="error"
            small
            v-if="parseInt(datosArticulo.articuloDescuento) > 0"
            elevation="0"
          >
            -{{ parseInt(datosArticulo.articuloDescuento) }}%
          </v-btn>
        </v-row> -->
        <!-- Carrusel Imagenes Mini -->
        <v-card-text style="height: 177px">
          <v-row style="height: 170px">
            <v-carousel
              hide-delimiter-background 
              hide-delimiters
              height="170px"
              interval="5000"
              :show-arrows="false" 
            >
              <v-carousel-item
                v-for="(item,i) in datosArticulo.articuloImagenes"
                :key="i"
              >
                <v-img
                  :src="item.src"
                  style="width:85%;margin:auto;" 
                  :alt="item.src"
                >
                </v-img>
              </v-carousel-item>
            </v-carousel>
          </v-row>
          <!-- Articulo Categoria -->
          <v-row
            align="center"
            justify="center"
            class="mt-0"
            style="height: 7px"
          >
            <h4 class="grey--text formatoPalabras">
              <strong>{{datosArticulo.articuloCategoria}}</strong>
            </h4>
          </v-row>
        </v-card-text>
        <v-card-title style="height: 90px" class="mb-0 pb-0">
          <!-- Articulo Nombre -->
          <v-row
            justify="center"
            align="center"
            class="formatoPalabras pt-2"
            style="height: 90px"
          >
            <div class="headerClass">
              <p class="titulo" v-if="datosArticulo.articuloNombre.length<40" style="word-break: normal; font-size: 18px; line-height: 20px;">
                <strong>{{ datosArticulo.articuloNombre.toUpperCase() }}</strong>
              </p>
              <p class="titulo" v-else style="word-break: normal; font-size: 18px; line-height: 20px">
                <strong>{{ (datosArticulo.articuloNombre.substring(0,38) + '..').toUpperCase() }}</strong>
              </p>
              <!-- <h5 class="titulo ma-0 pa-0" v-if="datosArticulo.articuloNombre.length<35" style="word-break: normal;">
                {{ datosArticulo.articuloNombre.toUpperCase() }}
              </h5>
              <h5 class="titulo ma-0 pa-0" v-else style="word-break: normal;">
                {{ (datosArticulo.articuloNombre.substring(0,33) + '..').toUpperCase() }}
              </h5> -->
            </div>
          </v-row>
        </v-card-title>
        <v-card-text style="height: 60px" class="mt-2 pb-0">
          <!-- Articulo Precio -->
          <v-row
            align="center"
            justify="center"
            class="mb-0"
            style="height: 30px"
          >
            <del v-if="parseInt(datosArticulo.articuloDescuento) > 0"><p class="grey--text pt-3"><strong>${{Intl.NumberFormat("de-DE").format(parseInt(precioAnterior)) }}</strong></p></del><p v-if="parseInt(datosArticulo.articuloDescuento) > 0" class="green--text pt-3 pl-2"><strong>{{ parseInt(datosArticulo.articuloDescuento) }}%OFF</strong></p>
            <!-- <v-col cols="6" class="pl-0 pt-0 pb-0 pr-1">
              <del v-if="parseInt(datosArticulo.articuloDescuento) > 0"><p class="grey--text pt-3 ml-10"><strong>${{Intl.NumberFormat().format(parseInt(precioAnterior)) }}</strong></p></del>
            </v-col>
            <v-col cols="6" class="pl-1 pt-0 pb-0 pr-0">
              <v-chip
                class="formatoPalabras"
                color="green"
                v-if="parseInt(datosArticulo.articuloDescuento) > 0"
                outlined
                small
              >
                <strong>-{{ parseInt(datosArticulo.articuloDescuento) }}% OFF</strong>
              </v-chip> 
            </v-col> -->
            <v-col cols="12" class="pb-12 pt-0 pl-0 pr-0">
              <p class="titulo formatoPalabras" style="color: #E41B41; font-size: 25px"><strong>${{ Intl.NumberFormat("de-DE").format(datosArticulo.articuloPrecio) }}</strong></p>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions style="justify-content: center; height: 40px" class="mb-0 pb-0 pt-12">
          <v-btn
            rounded
            color="primary"
            dark
            height="40px"
            class="formatoPalabras"
            :outlined="showCarrito"
            @mouseenter="mostrarCarrito"
            @mouseleave="ocultarCarrito"
            @click.stop="agregarCarrito(datosArticulo)"
          >
            Agregar
            <v-icon
              right
              dark
              v-if="showCarrito"
            >
              mdi-cart-outline
            </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-hover>
  </v-container>
</template>

<script>
import router from '../../router'
export default {
  props: {
    datosArticulo: {
      type: Object,
      default: {}
    } 
  },
  data() {
    return {
      precioAnterior: '',
      showCarrito: false,
      drag: false
    }
  },
  created(){
    if (this.datosArticulo == null || this.datosArticulo == undefined) return
    document.addEventListener( 
        'mousedown', () => this.drag = false); 
      
    document.addEventListener( 
        'mousemove', () => this.drag = true); 
      
    /*document.addEventListener( 
        'mouseup', () => console.log( 
            this.drag ? 'drag' : 'click')); */
    this.recalculaPrecio(this.datosArticulo.articuloPrecio)

  },
  computed: {
    /*articuloImagenes: {
      get() {
          return this.datosArticulo.articuloImagenes
      }
    }*/
  },
  methods: {
    recalculaPrecio(precioNeto){
      if (parseInt(this.datosArticulo.articuloDescuento) > 0){
        /*let porcentaje = (parseFloat(this.datosArticulo.articuloDescuento).toFixed(2)/100).toFixed(2)
        let importe = parseFloat(precioNeto)*porcentaje
        this.precioAnterior = parseFloat(precioNeto)+importe*/
        //this.precioAnterior = ((parseFloat(precioNeto).toFixed(2)*100).toFixed(2)/parseFloat(this.datosArticulo.articuloDescuento).toFixed(2)).toFixed(2)
        let dif = parseFloat(100 - parseFloat(this.datosArticulo.articuloDescuento)).toFixed(2)
        this.precioAnterior = ((parseFloat(precioNeto*100).toFixed(2))/dif).toFixed(2)
      }
    },
    mostrarCarrito(){
      this.showCarrito = true
    },
    ocultarCarrito(){
      this.showCarrito = false
    },
    agregarCarrito(articuloSeleccionado){
      this.$emit('asignarAlCarrito', articuloSeleccionado)
    },
    verArticuloDetalle(event){
      if (this.drag == false){
        if (event.ctrlKey == true){
          // abrir en nueva pestaña
          let routeData = this.$router.resolve({name: 'ArticuloDetalle', params: {keyFirebase: this.datosArticulo.articuloKey}});
          window.open(routeData.href, '_blank');
        }else{
          // abrir en la misma pestaña
          router.push({name: 'ArticuloDetalle', params: {keyFirebase: this.datosArticulo.articuloKey}})
        }
        //router.push({name: 'ArticuloDetalle', params: {keyFirebase: this.datosArticulo.articuloKey}})
      }
    },
    abrirNuevaPestaña(){
      // abrir en nueva pestaña
      let routeData = this.$router.resolve({name: 'ArticuloDetalle', params: {keyFirebase: this.datosArticulo.articuloKey}});
      window.open(routeData.href, '_blank');
    }
  },
  watch: {
    datosArticulo: function(){
      if (Object.keys(this.datosArticulo).length > 0){
        this.recalculaPrecio(this.datosArticulo.articuloPrecio)
      }
    }
  },
}
</script>

<style scoped>
  .titulo{
    justify-content: center !important;
    align-content: center;
    text-align: center;
  }
  .v-card.on-hover.theme--dark{
    background-color: rgba(#FFF, 0.8)
  }
  .v-card__text{
    color: #000
  }
  .headerClass{
    white-space:normal ;
    word-break: normal;
    overflow: hidden ;
    text-overflow: ellipsis;
  }
  .formatoPalabras{
    font-family: 'Poppins', sans-serif;
  }
</style>